import React from 'react';
import { useHistory } from 'react-router-dom';
import CommonFooter from '../common/CommonFooter';

interface Props {
  isSelectedBaseModel: boolean;
}

const BaseModelFooter = (props: Props): JSX.Element => {
  const history = useHistory(); // eslint-disable-line

  const onClickBackButton = (): void => {
    history.push('/filtering_image');
  };

  const onClickConfirmButton = (): void => {
    history.push('/simulate');
  };

  return (
    <div>
      <CommonFooter
        canMoveToNextPage={props.isSelectedBaseModel}
        onClickBack={onClickBackButton}
        onClickNext={onClickConfirmButton}
        ableBackButton={true}
        nextButtonText="次へ"
      />
    </div>
  );
};

export default BaseModelFooter;
