import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import BaseModelPage from './pages/BaseModelPage';
import FilteringImagePage from './pages/FilteringImagePage';
import SelectPartsPage from './pages/SelectPartsPage';
function App(): JSX.Element {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/simulate">
          <SelectPartsPage />
        </Route>
      </Switch>
      <Switch>
        <Route exact path="/base_model">
          <BaseModelPage />
        </Route>
      </Switch>
      <Switch>
        <Route exact path="/filtering_image">
          <FilteringImagePage />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
