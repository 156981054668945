import React, { FC, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { CompoundModelUsecase } from '../../usecases/CompoundModelUsecase';
import { useHistory } from 'react-router-dom';
import CompoundModelData from '../../types/copied/dto/data/CompoundModelData';
import { UtilityTool } from '../../utility/UtilityTool';
import CommonModal from '../components/common/CommonModal';

interface Props {
  // モーダルの状態
  open: boolean;
  // モーダルを閉じる
  handleClose: () => void;
}
const useStyles = makeStyles(() => ({
  model_name: {
    marginTop: '12px',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '130%',
    letterSpacing: '0.03em',
    color: '#343434',
  },
  model_explanation: {
    marginTop: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '150%',
    color: '#343434',
  },
  model_sub_explanation: {
    marginTop: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '130%',
    color: '#343434',
  },
  close_button: {
    pointerEvents: 'none',
    border: 'none',
    background: 'transparent',
  },
  close_button_img: {
    height: '24px',
    width: '24px',
  },
  transparent_button: {
    border: 'none',
    background: 'transparent',
  },
  paste_button: {
    border: 'none',
    background: 'transparent',
    paddingRight: '0px',
  },
  root_input_field: {
    marginTop: '14px',
    display: 'flex',
    background: '#EFEFEF',
    border: '1px solid #D9D9D9',
    borderRadius: '48px',
    height: '40px',
  },
  error_input_field: {
    marginTop: '14px',
    display: 'flex',
    background: '#FFE6E6',
    border: '1px solid #F4B1A7',
    borderRadius: '48px',
    height: '40px',
  },
  input_icon: { margin: '10px', marginTop: 'auto', marginBottom: 'auto' },
  input_field: { fontSize: '16px', outline: 'none', border: 'none', background: 'transparent' },
  error_text: {
    color: '#FF0000',
    fontSize: '12px',
    fontWeight: '500',
    height: '24px',
    marginLeft: '18px',
  },
}));

const ResearchModelModal: FC<Props> = (props: Props) => {
  const handleClose = props.handleClose;
  const classes = useStyles();
  const history = useHistory();
  const [inputTextValue, setInputValue] = useState<string>('');
  const [isFirst, setIsFirst] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('');
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    if (UtilityTool.includeByteCharacters(inputTextValue, true)) {
      setErrorText('全角を含んでいます');
      setHasError(true);
    } else {
      setHasError(false);
      setErrorText('');
    }
  }, [inputTextValue]);

  const handleSubmitCompoundModelId = async (): Promise<void> => {
    if (isFirst) {
      if (isEmpty(inputTextValue)) {
        setErrorText('文字列の中身が空です');
        setHasError(true);
      } else {
        const res = await CompoundModelUsecase.getCompoundModel(inputTextValue);
        setIsFirst(true);
        if (res !== undefined) {
          //セッション情報に入れる。
          const result = setModelDataToSession(res);
          if (result) {
            setIsFirst(false);
            sessionStorage.setItem('isUploadDone', 'true');
            //ページ遷移
            if (history.location.pathname === '/simulate') {
              window.location.reload();
            } else {
              history.push('/simulate');
            }
            //リザルトページの保存
            sessionStorage.setItem('isResultPage', 'true');
            //ページを閉じる
            handleClose();
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({ event: 'search_model_success' });
          }
        } else {
          setErrorText(
            'こちらのモデルIDに該当する検索結果は現在表示できません。今一度、モデルIDをご確認ください。'
          );
          setHasError(true);
        }
      }
    }
  };

  const isEmpty = (key: string): boolean => {
    return key === '';
  };

  const setModelDataToSession = (res: CompoundModelData): boolean => {
    const washer = res.part_models.find((e) => {
      return e.item_type_name === 'type_washer';
    });
    if (washer !== undefined) {
      sessionStorage.setItem('washerId', washer.id);
    } else {
      return false;
    }

    const heating = res.part_models.find((e) => {
      return e.item_type_name === 'type_heating';
    });
    if (heating !== undefined) {
      sessionStorage.setItem('heatingId', heating.id);
    } else {
      return false;
    }
    const range = res.part_models.find((e) => {
      return e.item_type_name === 'type_range';
    });
    if (range !== undefined) {
      sessionStorage.setItem('rangeId', range.id);
    } else {
      return false;
    }
    const wetArea = res.part_models.find((e) => {
      return e.item_type_name === 'type_wet_area';
    });
    if (wetArea !== undefined) {
      const tableTopId = wetArea.item_selection_info_set_1_id;
      if (tableTopId !== undefined) {
        sessionStorage.setItem('tabletopId', tableTopId);
      } else {
        return false;
      }
      const sinkId = wetArea.item_selection_info_set_2_id;
      if (sinkId !== undefined) {
        sessionStorage.setItem('sinkId', sinkId);
      } else {
        return false;
      }
      const tapId = wetArea.item_selection_info_set_3_id;
      if (tapId !== undefined) {
        sessionStorage.setItem('tapId', tapId);
      } else {
        return false;
      }
    } else {
      return false;
    }

    const doorArea = res.part_models.find((e) => {
      return e.item_type_name === 'type_door_area';
    });
    if (doorArea !== undefined) {
      const doorDesignId = doorArea.item_selection_info_set_1_id;
      if (doorDesignId !== undefined) {
        sessionStorage.setItem('doorDesign', doorDesignId);
      } else {
        return false;
      }
      const doorColorId = doorArea.item_selection_info_set_2_id;
      if (doorColorId !== undefined) {
        sessionStorage.setItem('doorColor', doorColorId);
      } else {
        return false;
      }

      const doorGripId = doorArea.item_selection_info_set_3_id;
      if (doorGripId !== undefined) {
        sessionStorage.setItem('doorGrip', doorGripId);
      } else {
        return false;
      }
    } else {
      return false;
    }
    sessionStorage.setItem('compoundModelId', res.id);
    sessionStorage.setItem('arModelUrl', res.model_url);

    //sessionStorage.setItem();
    const base = res.base_model;
    if (base !== undefined) {
      sessionStorage.setItem('baseModelId', base.id);
      sessionStorage.setItem('baseModelUrl', base.model_url);
      sessionStorage.setItem('baseModelName', base.name);
      sessionStorage.setItem('baseModelPrice', base.price);
      sessionStorage.setItem('baseModelThumbnailUrl', base.thumbnail_url);
    } else {
      return false;
    }
    return true;
  };

  const OnClickPasteButton = async (): Promise<void> => {
    await global.navigator.clipboard.readText().then((clipText) => {
      setInputValue(clipText);
    });
  };

  const contents = (
    <div>
      <div className={classes.model_name}>{'モデルIDの入力'}</div>
      <div className={classes.model_explanation}>
        {
          'お控えいただいたモデルIDを入力していただくことで、作成いただいたプランを確認していただけます。'
        }
      </div>
      <div className={classes.model_sub_explanation}>
        {
          '※モデルIDはショールームでお見積りの際に使用しますが、ご来店の前に、今一度ご確認ください。'
        }
      </div>
      <div className={classes.model_sub_explanation}>
        {'※モデルIDがご不明な場合は、お手数ですがもう一度プランニングしていただく必要があります。'}
      </div>
      <div>
        <div className={hasError ? classes.error_input_field : classes.root_input_field}>
          <button
            className={classes.paste_button}
            onClick={() => {
              OnClickPasteButton();
            }}
          >
            <div className={classes.input_icon}>
              <img src="svg/clip_icon.svg" />
            </div>
          </button>
          <input
            type="text"
            value={inputTextValue}
            onChange={handleInputChange}
            placeholder="モデルID"
            size={15}
            className={classes.input_field}
          />
        </div>
        {hasError ? (
          <div className={classes.error_text}>{errorText}</div>
        ) : (
          <div className={classes.error_text}>{''}</div>
        )}
      </div>
      <div>
        <div style={{ marginTop: '16px', display: 'flex', justifyContent: 'flex-end' }}>
          <button
            onClick={handleSubmitCompoundModelId}
            className={classes.transparent_button}
            disabled={hasError}
          >
            <img src="svg/submit_button.svg" />
          </button>
        </div>
      </div>
    </div>
  );

  return <CommonModal open={props.open} handleClose={handleClose} contents={contents} />;
};

export default ResearchModelModal;
