import ItemSelectionData from '../types/copied/dto/data/ItemSelectionData';
import PartModelData from '../types/copied/dto/data/PartModelData';
/**
 * パーツモデルデータから選択肢を作成する操作に関する
 */
/* eslint-disable */
export class UtilityWithSelection {
  /* eslint-disable */
  /**
   * 依存関係のないパーツモデルの選択肢作成
   */
  public static extractSingleList = (
    itemType: string,
    partModelDataSet: PartModelData[]
  ): PartModelData[] => {
    const filterData = partModelDataSet.filter((d) => {
      const canCreateCard = (): boolean => {
        // 対象のパーツモデルでないなら返さない。
        if (d.item_type_name !== itemType) {
          return false;
        }
        // 理論削除されていたら返さない。
        if (d.is_deleted === 'true') {
          return false;
        }
        return true;
      };

      return canCreateCard();
    });
    filterData.sort(function (a, b) {
      if (a.id > b.id) {
        return 1;
      } else {
        return -1;
      }
    });

    return filterData;
  };

  /**
   * 依存関係のあるパーツモデルの1番目の選択肢の作成
   */
  public static extractLayer1List = (
    itemType: string,
    partModelDataSet: PartModelData[],
    itemSelections: ItemSelectionData[]
  ): PartModelData[] => {
    const filterData = partModelDataSet.filter((d) => {
      if (d.item_type_name !== itemType) {
        return false;
      }
      // 理論削除されていたら返さない。
      if (d.is_deleted === 'true') {
        return false;
      }
      return true;
    });
    // res1の作成---------------------
    const res1 = filterData.map((d) => {
      let targetItemSelection = itemSelections.find((e) => {
        return e.id === d.item_selection_info_set_1_id;
      });
      if (targetItemSelection === undefined) {
        targetItemSelection = {
          id: '',
          name: '',
          thumbnail_url: '',
          explain: '',
          item_type_name: '',
          sort: '',
        };
      }
      const resModel: PartModelData = {
        id: targetItemSelection.id,
        model_url: '',
        item_info_id: '',
        name: targetItemSelection.name,
        price: '',
        item_type_name: targetItemSelection.item_type_name,
        thumbnail_url: targetItemSelection.thumbnail_url,
        parent_item_info_id: targetItemSelection.sort,
        explain: targetItemSelection.explain,
        is_deleted: '',
        item_selection_info_set_1_id: undefined,
        item_selection_info_set_2_id: undefined,
        item_selection_info_set_3_id: undefined,
      };

      return resModel;
    });
    // 重複削除
    const uniqueRes1 = Array.from(new Map(res1.map((c) => [c.id, c])).values());
    const sortedRes1 = uniqueRes1.sort(function (a, b) {
      if (a.parent_item_info_id > b.parent_item_info_id) {
        return 1;
      } else {
        return -1;
      }
    });

    return sortedRes1;
  };

  /**
   * 依存関係のあるパーツモデルの2番目の選択肢の作成
   */
  public static extractLayer2List = (
    itemType: string,
    rootModel: PartModelData,
    partModelDataSet: PartModelData[],
    itemSelections: ItemSelectionData[]
  ) => {
    const filterData = partModelDataSet.filter((d) => {
      if (d.item_type_name !== itemType) {
        return false;
      }
      // 理論削除されていたら返さない。
      if (d.is_deleted === 'true') {
        return false;
      }
      return true;
    });
    // -res2の作成---------------------
    const res2 = filterData.map((d) => {
      let targetItemSelection = itemSelections.find((e) => {
        return e.id === d.item_selection_info_set_2_id;
      });
      if (targetItemSelection === undefined) {
        targetItemSelection = {
          id: '',
          name: '',
          thumbnail_url: '',
          explain: '',
          item_type_name: '',
          sort: '',
        };
      }
      const resModel: PartModelData = {
        id: targetItemSelection.id,
        model_url: '',
        item_info_id: '',
        name: targetItemSelection.name,
        price: '',
        item_type_name: targetItemSelection.item_type_name,
        thumbnail_url: targetItemSelection.thumbnail_url,
        parent_item_info_id: targetItemSelection.sort,
        explain: targetItemSelection.explain,
        is_deleted: '',
        item_selection_info_set_1_id: undefined,
        item_selection_info_set_2_id: undefined,
        item_selection_info_set_3_id: undefined,
      };
      return resModel;
    });
    // 重複削除
    const uniqueRes2 = Array.from(new Map(res2.map((c) => [c.id, c])).values());

    // 依存関係のある選択肢を削る
    const akinIdListRes2 = filterData
      .filter((e) => {
        //今選ばれている天板と同じもの
        if (e.item_selection_info_set_1_id === rootModel.id) {
          return true;
        }
      })
      .map((d) => {
        return d.item_selection_info_set_2_id;
      });
    const allowRes2 = uniqueRes2.filter((d) => {
      return akinIdListRes2.includes(d.id);
    });

    const sortedRes2 = allowRes2.sort(function (a, b) {
      if (a.parent_item_info_id > b.parent_item_info_id) {
        return 1;
      } else {
        return -1;
      }
    });
    return sortedRes2;
  };

  /**
   * 依存関係のあるパーツモデルの3番目の選択肢の作成
   */
  public static extractLayer3List = (
    itemType: string,
    rootModel: PartModelData,
    rootModel2: PartModelData,
    partModelDataSet: PartModelData[],
    itemSelections: ItemSelectionData[]
  ) => {
    const filterData = partModelDataSet.filter((d) => {
      if (d.item_type_name !== itemType) {
        return false;
      }
      // 理論削除されていたら返さない。
      if (d.is_deleted === 'true') {
        return false;
      }
      return true;
    });
    // -res3の作成---------------------
    const res3 = filterData.map((d, index) => {
      let targetItemSelection = itemSelections.find((e) => {
        return e.id === d.item_selection_info_set_3_id;
      });
      if (targetItemSelection === undefined) {
        targetItemSelection = {
          id: '',
          name: '',
          thumbnail_url: '',
          explain: '',
          item_type_name: '',
          sort: '',
        };
      }
      const resModel: PartModelData = {
        id: targetItemSelection.id,
        model_url: '',
        item_info_id: '',
        name: targetItemSelection.name,
        price: '',
        item_type_name: targetItemSelection.item_type_name,
        thumbnail_url: targetItemSelection.thumbnail_url,
        parent_item_info_id: targetItemSelection.sort,
        explain: targetItemSelection.explain,
        is_deleted: '',
        item_selection_info_set_1_id: undefined,
        item_selection_info_set_2_id: undefined,
        item_selection_info_set_3_id: undefined,
      };
      return resModel;
    });

    // 重複削除
    const uniqueRes3 = Array.from(new Map(res3.map((c) => [c.id, c])).values());
    // 依存関係のある選択肢を削る
    const akinIdList = filterData
      .filter((e) => {
        if (rootModel === undefined) {
          return false;
        }
        if (rootModel2 === undefined) {
          return false;
        }
        if (e.item_selection_info_set_1_id === rootModel.id) {
          if (e.item_selection_info_set_2_id === rootModel2.id) {
            return true;
          }
        }
      })
      .map((d) => {
        return d.item_selection_info_set_3_id;
      });
    const allowRes3 = uniqueRes3.filter((d) => {
      return akinIdList.includes(d.id);
    });

    const sortedRes3 = allowRes3.sort(function (a, b) {
      if (a.id > b.id) {
        return 1;
      } else {
        return -1;
      }
    });
    return sortedRes3;
  };

  /**
   * 分割したパーツモデルを統合する
   */

  public static integrateAreaModel = (
    _item_selection_info_set_1_id: string,
    _item_selection_info_set_2_id: string,
    _item_selection_info_set_3_id: string,
    _itemType_name: string,
    partModelDataSet: PartModelData[]
  ) => {
    const targetModelData = partModelDataSet.filter((d) => {
      const canCreateCard = () => {
        if (d.item_type_name !== _itemType_name) {
          return false;
        }
        if (d.is_deleted === 'true') {
          return false;
        }
        return true;
      };
      return canCreateCard();
    });
    const targetModelA = targetModelData.find((d) => {
      if (d.item_selection_info_set_1_id === _item_selection_info_set_1_id) {
        if (d.item_selection_info_set_2_id === _item_selection_info_set_2_id) {
          if (d.item_selection_info_set_3_id === _item_selection_info_set_3_id) {
            return true;
          }
        }
      }
      return false;
    });

    if (targetModelA === undefined) {
      return {
        id: '',
        model_url: '',
        item_info_id: '',
        name: '',
        price: '',
        item_type_name: '',
        thumbnail_url: '',
        parent_item_info_id: '',
        explain: '',
        is_deleted: '',
        item_selection_info_set_1_id: '',
        item_selection_info_set_2_id: '',
        item_selection_info_set_3_id: '',
      };
    } else {
      return targetModelA;
    }
  };

  /**
   * 依存リストの更新(Layer2)
   * @return 更新後のlist
   */
  public static updateLayer2List = (
    partModelDataSet: PartModelData[],
    itemSelections: ItemSelectionData[],
    itemTypeName: string,
    selectedModel: PartModelData,
    setValue: (value: React.SetStateAction<PartModelData>) => void,
    setListValue: (value: React.SetStateAction<PartModelData[]>) => void
  ) => {
    // レイヤー2の列変更
    const updateLayer2List = UtilityWithSelection.extractLayer2List(
      itemTypeName,
      selectedModel,
      partModelDataSet,
      itemSelections
    );
    setListValue(updateLayer2List);
    setValue(updateLayer2List[0]);
    return updateLayer2List;
  };

  /**
   * 依存リストの更新(Layer3)
   * @return 更新後のlist
   */
  public static updateLayer3List = (
    partModelDataSet: PartModelData[],
    itemSelections: ItemSelectionData[],
    itemTypeName: string,
    rootLayer1Model: PartModelData,
    rootLayer2Model: PartModelData,
    setValue: (value: React.SetStateAction<PartModelData>) => void,
    setListValue: (value: React.SetStateAction<PartModelData[]>) => void
  ) => {
    // レイヤー3の列変更
    const updateLayer3List = UtilityWithSelection.extractLayer3List(
      itemTypeName,
      rootLayer1Model,
      rootLayer2Model,
      partModelDataSet,
      itemSelections
    );
    setListValue(updateLayer3List);
    setValue(updateLayer3List[0]);
    return updateLayer3List;
  };

  /**
   * 依存関係なく全ての選択肢を取得する。
   */
  public static getAllLayer2WithoutDependence = (
    partModelDataSet: PartModelData[],
    itemSelections: ItemSelectionData[],
    itemType: string
  ) => {
    const filterData = partModelDataSet.filter((d) => {
      if (d.item_type_name !== itemType) {
        return false;
      }
      // 理論削除されていたら返さない。
      if (d.is_deleted === 'true') {
        return false;
      }
      return true;
    });
    // -res2の作成---------------------
    const res2 = filterData.map((d) => {
      let targetItemSelection = itemSelections.find((e) => {
        return e.id === d.item_selection_info_set_2_id;
      });
      if (targetItemSelection === undefined) {
        targetItemSelection = {
          id: '',
          name: '',
          thumbnail_url: '',
          explain: '',
          item_type_name: '',
          sort: '',
        };
      }
      const resModel: PartModelData = {
        id: targetItemSelection.id,
        model_url: '',
        item_info_id: '',
        name: targetItemSelection.name,
        price: '',
        item_type_name: targetItemSelection.item_type_name,
        thumbnail_url: targetItemSelection.thumbnail_url,
        parent_item_info_id: '',
        explain: targetItemSelection.explain,
        is_deleted: '',
        item_selection_info_set_1_id: undefined,
        item_selection_info_set_2_id: undefined,
        item_selection_info_set_3_id: undefined,
      };
      return resModel;
    });
    // 重複削除
    const uniqueRes2 = Array.from(new Map(res2.map((c) => [c.id, c])).values());

    return uniqueRes2;
  };

  /**
   * 依存関係なく全ての選択肢を取得する。
   */
  public static getAllLayer3WithoutDependence = (
    partModelDataSet: PartModelData[],
    itemSelections: ItemSelectionData[],
    itemType: string
  ) => {
    const filterData = partModelDataSet.filter((d) => {
      if (d.item_type_name !== itemType) {
        return false;
      }
      // 理論削除されていたら返さない。
      if (d.is_deleted === 'true') {
        return false;
      }
      return true;
    });
    // -res3の作成---------------------
    const res3 = filterData.map((d) => {
      let targetItemSelection = itemSelections.find((e) => {
        return e.id === d.item_selection_info_set_3_id;
      });
      if (targetItemSelection === undefined) {
        targetItemSelection = {
          id: '',
          name: '',
          thumbnail_url: '',
          explain: '',
          item_type_name: '',
          sort: '',
        };
      }
      const resModel: PartModelData = {
        id: targetItemSelection.id,
        model_url: '',
        item_info_id: '',
        name: targetItemSelection.name,
        price: '',
        item_type_name: targetItemSelection.item_type_name,
        thumbnail_url: targetItemSelection.thumbnail_url,
        parent_item_info_id: '',
        explain: targetItemSelection.explain,
        is_deleted: '',
        item_selection_info_set_1_id: undefined,
        item_selection_info_set_2_id: undefined,
        item_selection_info_set_3_id: undefined,
      };
      return resModel;
    });
    // 重複削除
    const uniqueRes2 = Array.from(new Map(res3.map((c) => [c.id, c])).values());

    return uniqueRes2;
  };

  /**
   * 依存関係のある選択肢を作成する。
   */

  public static select3Elements = (
    partModelDataSet: PartModelData[],
    itemSelections: ItemSelectionData[],
    itemTypeName: string,
    selectedLayer1: PartModelData,
    setLayer1Value: (value: React.SetStateAction<PartModelData>) => void,
    selectedLayer2: PartModelData,
    setLayer2Value: (value: React.SetStateAction<PartModelData>) => void,
    setLayer2ListValue: (value: React.SetStateAction<PartModelData[]>) => void,
    selectedLayer3: PartModelData,
    setLayer3Value: (value: React.SetStateAction<PartModelData>) => void,
    setLayer3ListValue: (value: React.SetStateAction<PartModelData[]>) => void
  ) => {
    //選択されたモデルを変更
    setLayer1Value(selectedLayer1);

    //表示される選択肢を変更
    // レイヤー2の列変更
    const updateLayer2List = UtilityWithSelection.extractLayer2List(
      itemTypeName,
      selectedLayer1,
      partModelDataSet,
      itemSelections
    );
    setLayer2ListValue(updateLayer2List);

    setLayer2Value(selectedLayer2);
    //レイヤー3の列変更
    const updateLayer3List = UtilityWithSelection.extractLayer3List(
      itemTypeName,
      selectedLayer1,
      selectedLayer2,
      partModelDataSet,
      itemSelections
    );
    setLayer3ListValue(updateLayer3List);
    setLayer3Value(selectedLayer3);
  };
}
