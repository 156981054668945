import BaseModelData from '../types/copied/dto/data/BaseModelData';
import ComplexPriceData from '../types/copied/dto/data/ComplexPriceData';
import CompoundModelData from '../types/copied/dto/data/CompoundModelData';
import PartModelData from '../types/copied/dto/data/PartModelData';
import RenderingModelsOrder from '../types/original/dto/RenderingModelsOrder';
/* eslint-disable */
export class UtilityTool {
  /* eslint-disable */
  /**
   * 合計金額を算出する関数
   */
  public static calculateCost = (
    req: RenderingModelsOrder | undefined,
    complexDataSet: ComplexPriceData[]
  ): string => {
    if (req === undefined) {
      return '';
    }

    if (complexDataSet.length === 0) {
      return '';
    }

    const targetPrice = complexDataSet.find((e) => {
      //　食洗機のIDが一致
      if (req.washer.item_info_id === e.part_model_id) {
        if (e.door_grade === req.door_area.item_selection_info_set_1_id) {
          return true;
        }
      }
    });

    const targetOptionalPrice = complexDataSet.find((e) => {
      //　取手のIDが一致
      if (req.washer.item_info_id === e.part_model_id) {
        if (e.door_grade === req.door_area.item_selection_info_set_3_id) {
          return true;
        }
      }
    });

    const targetWetAreaPrice = complexDataSet.find((e) => {
      //　IDが一致
      if (e.part_model_id === req.wet_area.item_info_id) {
        return true;
      }
    });

    let complexPrice = '0';

    let complexOptionalPrice = '0';

    let wetAreaPrice = '0';

    if (targetPrice !== undefined) {
      complexPrice = targetPrice.price;
    }
    if (targetOptionalPrice !== undefined) {
      complexOptionalPrice = targetOptionalPrice.price;
    }
    if (targetWetAreaPrice !== undefined) {
      wetAreaPrice = targetWetAreaPrice.price;
    }
    /*
    //console.log('価格試算');
    //console.log('req.range.price' + req.range.price);
    //console.log('req.heating.price' + req.heating.price);
    //console.log('complexOptionalPrice' + complexOptionalPrice);
    //console.log('complexPrice' + complexPrice);
    //console.log('wetAreaPrice' + wetAreaPrice);
    */

    const samPrice =
      Number(req.range.price) +
      // Number(req.washer.price) +
      // Number(req.wet_area.price) +
      //Number(req.door_area.price) +
      Number(req.heating.price) +
      //Number(req.baseModel.price) +
      Number(complexOptionalPrice) +
      Number(complexPrice) +
      Number(wetAreaPrice);

    return String(samPrice * 1.1);
  };

  /**
   * 空白のパーツモデル
   */
  public static getEmptyPartModelData = (): PartModelData => {
    const emptyPartModelData: PartModelData = {
      id: '',
      model_url: '',
      item_info_id: '',
      name: '',
      price: '',
      item_type_name: '',
      thumbnail_url: '',
      parent_item_info_id: '',
      explain: '',
      is_deleted: '',
      item_selection_info_set_1_id: undefined,
      item_selection_info_set_2_id: undefined,
      item_selection_info_set_3_id: undefined,
    };
    return emptyPartModelData;
  };
  /**
   * 空のベースモデル
   */
  public static getEmptyBaseData = (): BaseModelData => {
    const emptyBaseModelData: BaseModelData = {
      id: '',
      model_url: '',
      item_info_id: '',
      name: '',
      price: '',
      item_number: '',
      thumbnail_url: '',
    };
    return emptyBaseModelData;
  };
  /**
   * 空の合成モデル
   */
  public static getEmptyCompoundData = (): CompoundModelData => {
    const emptyCompoundModelData: CompoundModelData = {
      id: '',
      model_url: '',
      base_model: this.getEmptyBaseData(),
      part_models: [],
    };
    return emptyCompoundModelData;
  };
  /**
   *idからPartModelを検索する。idがnullだった場合は初期血なので、listの一番最初を返す。
   */
  public static getByID = (id: string | null, list: PartModelData[]): PartModelData => {
    const res =
      id !== null
        ? list.find((e) => {
            return e.id === id;
          })
        : list[0];
    return res !== undefined ? res : this.getEmptyPartModelData();
  };

  /**
   * 現在のセッションデータからベースモデルを作成する。
   */
  public static getSessionBaseModelData = (): BaseModelData | undefined => {
    const baseModelId = sessionStorage.getItem('baseModelId');
    const baseModelUrl = sessionStorage.getItem('baseModelUrl');
    const baseModelName = sessionStorage.getItem('baseModelName');
    const baseModelPrice = sessionStorage.getItem('baseModelPrice');
    const baseModelThumbnailUrl = sessionStorage.getItem('baseModelThumbnailUrl');

    const isIncludeDefect = (): boolean => {
      if (baseModelId === null) {
        return true;
      }
      if (baseModelUrl === null) {
        return true;
      }
      if (baseModelName === null) {
        return true;
      }
      if (baseModelPrice === null) {
        return true;
      }
      if (baseModelThumbnailUrl === null) {
        return true;
      }
      return false;
    };

    if (isIncludeDefect()) {
      return undefined;
    } else {
      return {
        id: baseModelId!,
        model_url: baseModelUrl!,
        item_info_id: '',
        name: baseModelName!,
        price: baseModelPrice!,
        item_number: '',
        thumbnail_url: baseModelThumbnailUrl!,
      };
    }
  };

  /**
   * 引数に含まれている文字の中に、全角が含まれているか判別する(flgがfalseの場合は半角が含まれているか判別する。)
   */
  public static includeByteCharacters(str: string, flg: boolean) {
    for (var i = 0; i < str.length; i++) {
      var c = str.charCodeAt(i);
      // Shift_JIS: 0x0 ～ 0x80, 0xa0 , 0xa1 ～ 0xdf , 0xfd ～ 0xff
      // Unicode : 0x0 ～ 0x80, 0xf8f0, 0xff61 ～ 0xff9f, 0xf8f1 ～ 0xf8f3
      if (
        (c >= 0x0 && c < 0x81) ||
        c == 0xf8f0 ||
        (c >= 0xff61 && c < 0xffa0) ||
        (c >= 0xf8f1 && c < 0xf8f4)
      ) {
        if (!flg) return true;
      } else {
        if (flg) return true;
      }
    }
    return false;
  }

  /**
   * 乱数生成
   */
  public static createRandomString() {
    var S = 'abcdefghijkmnoprstuvwxyzABCDEFGHIJKLMNPQRSTUVWXYZ23456789';
    var N = 10;
    const randomString = Array.from(Array(N))
      .map(() => S[Math.floor(Math.random() * S.length)])
      .join('');
    return randomString;
  }

  /**
   * デバイス判別
   */
  public static isSmartPhone() {
    //const res = navigator.userAgent.match(/(iPhone|iPod|Android)/i);
    //return res;
    const device = () => {
      var ua = navigator.userAgent;
      if (
        ua.indexOf('iPhone') > 0 ||
        ua.indexOf('iPod') > 0 ||
        (ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0)
      ) {
        return 'mobile';
      } else if (ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0) {
        return 'tablet';
      } else {
        return 'desktop';
      }
    };
    // const res = navigator.userAgent.match(/(iPad)/i);
    if (device() === 'mobile') {
      return true;
    } else {
      return false;
    }
  }

  public static isTablet() {
    const device = () => {
      var ua = navigator.userAgent;
      if (
        ua.indexOf('iPhone') > 0 ||
        ua.indexOf('iPod') > 0 ||
        (ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0)
      ) {
        return 'mobile';
      } else if (ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0) {
        return 'tablet';
      } else {
        return 'desktop';
      }
    };
    if (device() === 'tablet') {
      return true;
    } else {
      return false;
    }
  }
}
