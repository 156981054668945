import * as THREE from 'three';
import ObjectManager from '../../three/manager/ObjectManager';
import RendererWrapper from '../../three/wrapper/RendererWrapper';
import SceneWrapper from '../../three/wrapper/SceneWrapper';
import React, { useEffect, useRef, useState } from 'react';
import { Color } from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import RenderingModelsOrder from '../../types/original/dto/RenderingModelsOrder';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { APP_ASSET_END_POINT } from '../../environment/environment';

interface Props {
  //　描画リクエスト
  req: RenderingModelsOrder | undefined;
  cameraPositionX: number;
  screenWidth: number;
  setIsDownLoadDone: (value: React.SetStateAction<boolean>) => void;
}
export default function Simulator(props: Props) {
  let canvasElement: HTMLCanvasElement;
  const defaultValueX = 30;
  const deltaValueX = 300;
  const [washer, setWasher] = useState<THREE.Group | undefined>(undefined);
  const prevWasher = usePrevious(washer);

  const [doorArea, setDoorArea] = useState<THREE.Group | undefined>(undefined);
  const prevDoorArea = usePrevious(doorArea);

  const [range, setRange] = useState<THREE.Group | undefined>(undefined);
  const prevRange = usePrevious(range);

  const [wetArea, setWetArea] = useState<THREE.Group | undefined>(undefined);
  const prevWetArea = usePrevious(wetArea);

  const [heating, setHeating] = useState<THREE.Group | undefined>(undefined);
  const prevHeating = usePrevious(heating);

  const [baseArea, setBaseArea] = useState<THREE.Group | undefined>(undefined);
  const prevBaseArea = usePrevious(baseArea);

  const [isDoneWasher, setIsDoneWasher] = useState<boolean>(false);
  const [isDoneRange, setIsDoneRange] = useState<boolean>(false);
  const [isDoneDoorArea, setIsDoneDoorArea] = useState<boolean>(false);
  const [isDoneWetArea, setIsDoneWetArea] = useState<boolean>(false);
  const [isDoneBase, setIsDoneBase] = useState<boolean>(false);
  const [isDoneHeating, setIsDoneHeating] = useState<boolean>(false);

  function usePrevious(value: any) {
    const ref = useRef(null);
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  // 初期読み込み
  useEffect(() => {
    (async () => {
      await init();
    })();
  }, []);

  useEffect(() => {
    if (
      ![
        isDoneWasher,
        isDoneRange,
        isDoneDoorArea,
        isDoneWetArea,
        isDoneBase,
        isDoneHeating,
      ].includes(false)
    ) {
      props.setIsDownLoadDone(false);
    } else {
    }
  }, [isDoneWasher, isDoneRange, isDoneDoorArea, isDoneWetArea, isDoneBase, isDoneHeating]);

  // 食洗機読み込み
  useEffect(() => {
    if (ObjectManager.scene !== undefined) {
      if (props.req !== undefined) {
        if (washer !== undefined) {
          if (prevWasher !== null) {
            ObjectManager.scene.remove(prevWasher);
          }
          ObjectManager.scene!.add(washer);
          setIsDoneWasher(true);
        }
      }
    }
  }, [washer]);

  // レンジ読み込み
  useEffect(() => {
    if (ObjectManager.scene !== undefined) {
      if (props.req !== undefined) {
        if (range !== undefined) {
          if (prevRange !== null) {
            ObjectManager.scene.remove(prevRange);
          }
          ObjectManager.scene!.add(range);
          setIsDoneRange(true);
        }
      }
    }
  }, [range]);

  // 加熱機器読み込み
  useEffect(() => {
    if (ObjectManager.scene !== undefined) {
      if (props.req !== undefined) {
        if (heating !== undefined) {
          if (prevHeating !== null) {
            ObjectManager.scene.remove(prevHeating);
          }
          ObjectManager.scene!.add(heating);
          setIsDoneHeating(true);
        }
      }
    }
  }, [heating]);

  // wetArea読み込み
  useEffect(() => {
    if (ObjectManager.scene !== undefined) {
      if (props.req !== undefined) {
        if (wetArea !== undefined) {
          if (prevWetArea !== null) {
            ObjectManager.scene.remove(prevWetArea);
          }
          ObjectManager.scene!.add(wetArea);
          setIsDoneWetArea(true);
        }
      }
    }
  }, [wetArea]);

  // ドアエリア読み込み
  useEffect(() => {
    if (ObjectManager.scene !== undefined) {
      if (props.req !== undefined) {
        if (doorArea !== undefined) {
          if (prevDoorArea !== null) {
            ObjectManager.scene.remove(prevDoorArea);
          }
          ObjectManager.scene!.add(doorArea);
          setIsDoneDoorArea(true);
        }
      }
    }
  }, [doorArea]);

  //　ベースモデル読み込み
  useEffect(() => {
    if (ObjectManager.scene !== undefined) {
      if (props.req !== undefined) {
        if (baseArea !== undefined) {
          if (prevBaseArea !== null) {
            ObjectManager.scene.remove(prevBaseArea);
          }
          ObjectManager.scene!.add(baseArea);
          setIsDoneBase(true);
        }
      }
    }
  }, [baseArea]);

  useEffect(() => {
    updateRenderObjects();
  }, [props.req]);

  function updateRenderObjects() {
    if (ObjectManager.scene !== undefined) {
      if (props.req !== undefined) {
        props.setIsDownLoadDone(true);
        setIsDoneBase(false);
        setIsDoneDoorArea(false);
        setIsDoneWasher(false);
        setIsDoneRange(false);
        setIsDoneWetArea(false);
        setIsDoneHeating(false);

        //ベースモデル作成
        new GLTFLoader().load(
          APP_ASSET_END_POINT +
            props.req.baseModel.model_url +
            '/' +
            props.req.door_color_id +
            '.glb',
          function (glb) {
            glb.scene.position.set(0, 0, 0);
            glb.scene.scale.set(1, 1, 1);
            setBaseArea(glb.scene);
          }
        );

        // 加熱機器
        new GLTFLoader().load(APP_ASSET_END_POINT + props.req.heating.model_url, function (glb) {
          glb.scene.position.set(0, 0, 0);
          glb.scene.scale.set(1, 1, 1);
          setHeating(glb.scene);
        });

        // 水回り
        new GLTFLoader().load(APP_ASSET_END_POINT + props.req.wet_area.model_url, function (glb) {
          glb.scene.position.set(0, 0, 0);
          glb.scene.scale.set(1, 1, 1);
          setWetArea(glb.scene);
        });

        //　レンジフードの作成
        const modelPath: string = APP_ASSET_END_POINT + props.req.range.model_url;
        new GLTFLoader().load(modelPath, function (glb) {
          glb.scene.position.set(0, 0, 0);
          glb.scene.scale.set(1, 1, 1);
          setRange(glb.scene);
        });

        // 食洗機
        let subPathName = '';
        if (props.req.washer.parent_item_info_id === 'have_handle') {
          subPathName = props.req.door_area.item_info_id;
        } else {
          subPathName = props.req.door_color_id;
        }
        new GLTFLoader().load(
          APP_ASSET_END_POINT + props.req.washer.model_url + '/' + subPathName + '.glb',
          function (glb) {
            glb.scene.position.set(0, 0, 0);
            glb.scene.scale.set(1, 1, 1);
            setWasher(glb.scene);
          }
        );

        //　扉まわりの作成
        new GLTFLoader().load(APP_ASSET_END_POINT + props.req.door_area.model_url, function (glb) {
          glb.scene.position.set(0, 0, 0);
          glb.scene.scale.set(1, 1, 1);
          setDoorArea(glb.scene);
        });
      }
    }
  }

  /**
   * 初期読み込み関数
   * @returns
   */
  async function init() {
    // canvas要素を取得
    const elm = document.getElementById('simulateCanvas');
    if (elm == null) {
      return;
    }
    canvasElement = elm as HTMLCanvasElement;

    // シーン設定
    ObjectManager.scene = new SceneWrapper();
    ObjectManager.scene.background = new Color('#D8E8E2');

    // カメラ
    const calculateValueX = defaultValueX - (deltaValueX * (50 - props.cameraPositionX)) / 100;
    ObjectManager.camera = new THREE.PerspectiveCamera(45, 343 / 200, 1, 2500);
    ObjectManager.camera.position.set(0, 2, 4);
    ObjectManager.camera.name = 'PerspectiveCamera';
    const camera = ObjectManager.camera;

    // レンダラー
    ObjectManager.renderer = new RendererWrapper(canvasElement);

    /*
    // ---------------------------------------------------------------------
    // Ambient light作成
    // ---------------------------------------------------------------------
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.2);
    ambientLight.name = 'AmbientLight';
    ObjectManager.scene.add(ambientLight);
*/
    // ---------------------------------------------------------------------
    // DirectLight　作成
    // ---------------------------------------------------------------------

    const dirLight = new THREE.DirectionalLight(0xffffff, 8);
    dirLight.target.position.set(0, 100, -10);
    dirLight.add(dirLight.target);
    dirLight.lookAt(0, 0, 0);
    dirLight.name = 'DirectionalLight';
    ObjectManager.light1 = dirLight;
    ObjectManager.scene.add(dirLight);

    const dirLight2 = new THREE.DirectionalLight(0xffffff, 8);
    dirLight2.target.position.set(0, -10, -10);
    dirLight2.add(dirLight2.target);
    dirLight2.lookAt(0, 0, 0);
    dirLight2.name = 'DirectionalLight2';
    ObjectManager.light2 = dirLight2;
    ObjectManager.scene.add(dirLight2);

    const dirLight3 = new THREE.DirectionalLight(0xffffff, 8);
    dirLight3.target.position.set(10, 0, 0);
    dirLight3.add(dirLight3.target);
    dirLight3.lookAt(0, 0, 0);
    dirLight3.name = 'DirectionalLight3';
    ObjectManager.light3 = dirLight3;
    ObjectManager.scene.add(dirLight3);

    const dirLight4 = new THREE.DirectionalLight(0xffffff, 8);
    dirLight4.target.position.set(-10, 0, -10);
    dirLight4.add(dirLight4.target);
    dirLight4.lookAt(0, 0, 0);
    dirLight4.name = 'DirectionalLight4';
    ObjectManager.light4 = dirLight4;
    ObjectManager.scene.add(dirLight4);

    const dirLight5 = new THREE.DirectionalLight(0xffffff, 4);
    dirLight5.target.position.set(0, 0, 10);
    dirLight5.add(dirLight5.target);
    dirLight5.lookAt(0, 0, 0);
    dirLight5.name = 'DirectionalLight5';
    ObjectManager.light5 = dirLight5;
    ObjectManager.scene.add(dirLight5);

    /*
    const light5 = new THREE.PointLight(0xffffff, 10, 500, 1.0);
    light5.position.set(0, -10, 0);
    ObjectManager.light5 = light5;
    ObjectManager.scene.add(light5);
    */

    // Grid作成---------------------------------------------------------------------
    const gridHelper = new THREE.GridHelper(2000, 20, 0x888888, 0x444444);
    gridHelper.position.y = -50;
    gridHelper.name = 'Grid';

    //コントローラー ------------------------------------------------------------------
    const controls = new OrbitControls(camera, ObjectManager.renderer.domElement);
    //controls.target.set(calculateValueX / 10, 0, 0);
    controls.target.set(0, 1.2, 0);
    controls.update();
    controls.enableDamping = true;
    controls.minDistance = 2.5;
    controls.maxDistance = 6;
    ObjectManager.controller = controls;

    // アニメーション開始
    animation();

    //基準点
    const geometry = new THREE.BoxGeometry();
    const material = new THREE.MeshPhongMaterial({ color: 0x00ff00 });
    const cube = new THREE.Mesh(geometry, material);
    cube.position.set(0, 0, 0);
    cube.scale.set(1, 1, 1);

    //////////////////////////////////

    ObjectManager.renderer.setSize(props.screenWidth, (props.screenWidth * 200) / 343);
    /**
     * 毎フレーム更新するアニメーション関数
     */
    function animation() {
      render();
      requestAnimationFrame(animation);
    }

    function render() {
      if (ObjectManager.controller !== undefined) {
        ObjectManager.controller.update();
      }
      if (ObjectManager.camera !== undefined) {
        if (ObjectManager.scene !== undefined) {
          if (ObjectManager.renderer !== undefined) {
            ObjectManager.renderer.render(ObjectManager.scene, ObjectManager.camera);
          }
        }
      }
    }
  }

  return <div></div>;
}
