import { useEffect, useState } from 'react';
import ItemSelectionData from '../../types/copied/dto/data/ItemSelectionData';
import PartModelData from '../../types/copied/dto/data/PartModelData';
import { UtilityTool } from '../../utility/UtilityTool';
import { UtilityWithSelection } from '../../utility/UtilityWithSelection';

/**
 * 選択されたパーツモデルを管理するためのhooks
 */

const useLayer1PartModels = (
  partModelDataSet: PartModelData[],
  itemSelections: ItemSelectionData[],
  typeName: string
): [PartModelData, (value: React.SetStateAction<PartModelData>) => void, PartModelData[]] => {
  const [selectedTabletop, setSelectedTabletop] = useState<PartModelData>(
    UtilityTool.getEmptyPartModelData()
  );
  const layer1List = UtilityWithSelection.extractLayer1List(
    typeName,
    partModelDataSet,
    itemSelections
  );
  const sessionId = () => {
    if (typeName === 'type_wet_area') {
      return 'tabletopId';
    }
    if (typeName === 'type_door_area') {
      return 'doorDesign';
    }
    return '';
  };

  // データベースからの情報が届いた時に実行する
  useEffect(() => {
    if (partModelDataSet.length === 0) {
      return;
    }
    if (itemSelections.length === 0) {
      return;
    }

    // 初期設定
    if (sessionStorage.getItem('tapId') === null) {
      setSelectedTabletop(layer1List[0]);
    } else {
      const layer1PartModel = UtilityTool.getByID(sessionStorage.getItem(sessionId()), layer1List);
      setSelectedTabletop(layer1PartModel);
    }
  }, [partModelDataSet.length, itemSelections.length]);

  return [selectedTabletop, setSelectedTabletop, layer1List];
};

export default useLayer1PartModels;
