import React, { FC, useState } from 'react';
import { makeStyles } from '@mui/styles';
import BaseModelData from '../../../types/copied/dto/data/BaseModelData';
import { Card, CardActionArea, CardMedia, SxProps } from '@mui/material';
import { APP_ASSET_END_POINT } from '../../../environment/environment';

interface Props {
  selectedBaseModel: BaseModelData | undefined;
  selectList: BaseModelData[];
  setValue: (value: React.SetStateAction<BaseModelData | undefined>) => void;
}

const useStyles = makeStyles(() => ({
  whole_base: {
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  row_base: {
    margin: '10px',
    marginTop: '0px',
    marginBottom: '0px',
    position: 'relative',
    paddingBottom: '0px',
  },
  selected_base: {
    position: 'absolute',
    top: '0px',
    right: '0px',
    background: 'rgba(63, 63, 63, 0.5)',
    width: '92vw',
    height: '69vw',
  },
  selected_icon: {
    position: 'absolute',
    top: '27vw',
    right: '41vw',
  },
  selected_icon_img: { width: '48px', height: '73px' },
  card_name: {
    marginTop: '10px',
    marginBottom: '0px',
    marginLeft: '10px',
    fontWeight: 700,
    fontStyle: 'normal',
    lineHeight: '130%',
    fontSize: '16px',
  },
}));

const styleClass = {
  cardMedia: {
    filter: 'drop-shadow(4px 3px 1px rgba(63, 63, 63, 0.2))',
    width: '92vw',
    height: '69vw',
    transform: 'translateZ(0)',
  },
  cardActionArea: {
    position: 'relative',
    width: '92vw',
    height: '69vw',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginBottom: '32px',
  },
  cardBase: {
    borderRadius: '0px',
    marginTop: '0px',
    position: 'relative',
    boxShadow: '0 0 0 0',
  },
};

//　横画面用
const useStylesPC = makeStyles(() => ({
  whole_base: {
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  row_base: {
    margin: '10px',
    marginTop: '0px',
    marginBottom: '0px',
    position: 'relative',
    paddingBottom: '0px',
  },
  selected_base: {
    position: 'absolute',
    top: '0px',
    right: '0px',
    background: 'rgba(63, 63, 63, 0.5)',
    width: '100%',
    height: '100%',
  },
  selected_icon: {
    position: 'absolute',
    top: '192px',
    right: '252px',
  },
  selected_icon_img: { width: '60px', height: '60px' },
  card_name: {
    marginTop: '10px',
    marginBottom: '0px',
    marginLeft: '10px',
    fontWeight: 700,
    fontStyle: 'normal',
    lineHeight: '130%',
    fontSize: '12px',
    width: '330px',
  },
}));

const styleClassPC = {
  cardMedia: {
    filter: 'drop-shadow(4px 3px 1px rgba(63, 63, 63, 0.2))',
    width: '100%',
    height: '100%',
    transform: 'translateZ(0)',
  },
  cardActionArea: {
    position: 'relative',
    width: '560px',
    height: '420px',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginBottom: '32px',
  },
  cardBase: {
    borderRadius: '0px',
    marginTop: '0px',
    position: 'relative',
    boxShadow: '0 0 0 0',
  },
};

const BaseModelSelectLine: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const classesPC = useStylesPC();
  const classesForResponsive = (_isSideways: boolean) => {
    if (_isSideways) {
      return classes;
    } else {
      return classesPC;
    }
  };

  const styleForResponsive = (_isSideways: boolean) => {
    if (_isSideways) {
      return styleClass;
    } else {
      return styleClassPC;
    }
  };

  const [isSideways, setIsSideWays] = useState<boolean>(window.orientation === 0);
  window.addEventListener('orientationchange', () => {
    const orientation = window.orientation;
    if (orientation !== 0) {
      setIsSideWays(false);
    } else {
      setIsSideWays(true);
    }
  });

  const CardElementsRow = (): JSX.Element[] => {
    return props.selectList.map((e, index) => {
      let selectImage;
      if (props.selectedBaseModel !== undefined) {
        if (e.id === props.selectedBaseModel.id) {
          selectImage = (
            <div>
              <div className={classesForResponsive(isSideways).selected_base}>
                <div className={classesForResponsive(isSideways).selected_icon}>
                  <img
                    src="svg/select.svg"
                    className={classesForResponsive(isSideways).selected_icon_img}
                  />
                </div>
              </div>
            </div>
          );
        } else {
          selectImage = <div></div>;
        }
      }
      return (
        <div style={{ marginLeft: '10px', marginRight: '10px' }}>
          <Card sx={styleForResponsive(isSideways).cardBase} key={index + 100}>
            <CardActionArea
              onClick={() => {
                props.setValue(e);
                sessionStorage.setItem('baseModelId', e.id);
                sessionStorage.setItem('baseModelUrl', e.model_url);
                sessionStorage.setItem('baseModelName', e.name);
                sessionStorage.setItem('baseModelPrice', e.price);
                sessionStorage.setItem('baseModelThumbnailUrl', e.thumbnail_url);
              }}
              sx={styleForResponsive(isSideways).cardActionArea}
            >
              <CardMedia
                component="img"
                image={APP_ASSET_END_POINT + e.thumbnail_url}
                loading="lazy"
                alt=""
                sx={styleForResponsive(isSideways).cardMedia}
              />
              <div
                style={{
                  background: 'rgb(223 221 219)',
                  filter: 'drop-shadow(4px 3px 1px rgba(63, 63, 63, 0.2))',
                  width: '100%',
                  fontSize: '12px',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  whiteSpace: 'pre-wrap',
                }}
              >
                <div>{` ${e.name}`}</div>
              </div>
              {selectImage}
            </CardActionArea>
          </Card>
        </div>
      );
    });
  };
  return <div className={classesForResponsive(isSideways).whole_base}>{CardElementsRow()}</div>;
};

export default BaseModelSelectLine;
