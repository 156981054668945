import { useState } from 'react';
import BaseModelData from '../../types/copied/dto/data/BaseModelData';
import { BaseModelUsecase } from '../../usecases/BaseModelUsecase';

/**
 * ベースモデルを管理するためのhooks
 */

const useBaseModel = (filteringImageId: string): [BaseModelData[], () => Promise<void>] => {
  const [partModels, setPartModel] = useState<BaseModelData[]>([]);
  /**
   * 該当ベースモデル取得
   */
  const getBaseModels = async (): Promise<void> => {
    const res = await BaseModelUsecase.getBaseModels(filteringImageId);
    if (res.length !== 0) {
      res.sort(function (a, b) {
        if (a.id > b.id) {
          return 1;
        } else {
          return -1;
        }
      });
      setPartModel(res);
    }
  };
  return [partModels, getBaseModels];
};

export default useBaseModel;
