import { useEffect, useState } from 'react';
import ItemSelectionData from '../../types/copied/dto/data/ItemSelectionData';
import PartModelData from '../../types/copied/dto/data/PartModelData';
import { UtilityTool } from '../../utility/UtilityTool';
import { UtilityWithSelection } from '../../utility/UtilityWithSelection';

/**
 * 選択されたパーツモデルを管理するためのhooks
 */

const useLayer2PartModels = (
  partModelDataSet: PartModelData[],
  itemSelections: ItemSelectionData[],
  typeName: string,
  selectedRootPartModel: PartModelData
): [
  PartModelData,
  (value: React.SetStateAction<PartModelData>) => void,
  PartModelData[],
  (value: React.SetStateAction<PartModelData[]>) => void
] => {
  const [selectedLayer2, setSelectedLayer2] = useState<PartModelData>(
    UtilityTool.getEmptyPartModelData()
  );
  const [list, setList] = useState<PartModelData[]>([]);
  const defaultList = UtilityWithSelection.extractLayer2List(
    typeName,
    selectedRootPartModel,
    partModelDataSet,
    itemSelections
  );
  const sessionId = () => {
    if (typeName === 'type_wet_area') {
      return 'sinkId';
    }
    if (typeName === 'type_door_area') {
      return 'doorColor';
    }
    return '';
  };

  // データベースからの情報が届いた時に実行する
  useEffect(() => {
    if (partModelDataSet.length === 0) {
      return;
    }
    if (itemSelections.length === 0) {
      return;
    }
    if (selectedRootPartModel.id === '') {
      return;
    }
    // 初期設定
    if (sessionStorage.getItem('tapId') === null) {
      setList(defaultList);
      setSelectedLayer2(defaultList[0]);
    } else {
      setList(defaultList);
      const layer2PartModel = UtilityTool.getByID(
        sessionStorage.getItem(sessionId()),
        UtilityWithSelection.getAllLayer2WithoutDependence(
          partModelDataSet,
          itemSelections,
          typeName
        )
      );
      setSelectedLayer2(layer2PartModel);
    }
  }, [partModelDataSet.length, itemSelections.length, selectedRootPartModel]);

  return [selectedLayer2, setSelectedLayer2, list, setList];
};

export default useLayer2PartModels;
