import { Box, Fade, Modal } from '@mui/material';
import React, { FC, useState } from 'react';
import { makeStyles } from '@mui/styles';

interface Props {
  // モーダルの状態
  open: boolean;
  // モーダルを閉じる
  handleClose: () => void;
  contents: JSX.Element;
}

const styleClass = {
  base: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 24,
    p: 4,
    padding: '16px',
    WebkitTapHighlightColor: 'rgba(0,0,0,0)',
    cursor: 'default',
    outline: 'none',
  },
};

const styleClassPC = {
  base: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    width: '30%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 24,
    p: 4,
    padding: '16px',
    WebkitTapHighlightColor: 'rgba(0,0,0,0)',
    cursor: 'default',
    outline: 'none',
  },
};

const useStyles = makeStyles((theme) => ({
  close_button_base: {
    position: 'absolute',
    right: '0px',
    top: '-32px',
    bottom: '0px',
    width: '30px',
    height: '30px',
    pointerEvents: 'none',
  },
  close_button_img: {
    height: '30px',
    width: '30px',
  },
  close_button: {
    pointerEvents: 'none',
    border: 'none',
    background: 'transparent',
    paddingLeft: '0px',
    paddingRight: '0px',
  },
}));

/**
 * 縦横回転に対応していない旨を見せる表示
 */
const CommonModal: FC<Props> = (props: Props) => {
  const open = props.open;
  const handleClose = props.handleClose;
  const content = props.contents;
  const classes = useStyles();

  const classesForResponsive = (_isSideways: boolean) => {
    if (_isSideways) {
      return styleClass;
    } else {
      return styleClassPC;
    }
  };

  const [isSideways, setIsSideWays] = useState<boolean>(window.orientation === 0);
  window.addEventListener('orientationchange', () => {
    const orientation = window.orientation;
    if (orientation !== 0) {
      setIsSideWays(false);
    } else {
      setIsSideWays(true);
    }
  });
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={open}>
          <Box sx={classesForResponsive(isSideways).base}>
            <div>{content}</div>
            <div className={classes.close_button_base}>
              <button className={classes.close_button}>
                <img src="svg/2xlCloseIcon.svg" className={classes.close_button_img} />
              </button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default CommonModal;
