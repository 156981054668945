import React, { useEffect, useState } from 'react';
import PartModelData from '../../types/copied/dto/data/PartModelData';
import PartModelExplainModal from '../modal/PartModelExplainModal';
import RenderingModelsOrder from '../../types/original/dto/RenderingModelsOrder';
import Simulator from '../simulator/Simulator';
import PartModelFooter from '../components/footer/PartModelFooter';
import HeightWidthModal from '../modal/HeightWidthModal';
import { makeStyles } from '@mui/styles';
import Header from '../components/header/Header';
import { UtilityWithSelection } from '../../utility/UtilityWithSelection';
import useSinglePartModel from '../hooks/useSinglePartModel';
import useLayer1PartModels from '../hooks/useLayer1PartModels';
import useLayer2PartModels from '../hooks/useLayer2PartModels';
import useLayer3PartModels from '../hooks/useLayer3PartModels';
import useItemSelection from '../hooks/useItemSelection';
import usePartModels from '../hooks/usePartModels';
import RootLowerResult from '../components/contents/RootLowerResult';
import ResultFooter from '../components/footer/ResultFooter';
import PartModelsLine from '../components/contents/PartModelsLine';
import ResultPageTitle from '../components/title/ResultPageTitle';
import SelectPageTitle from '../components/title/SelectPageTitle';
import ARResultSection from '../components/contents/ARResultSection';
import { UtilityTool } from '../../utility/UtilityTool';
import ResearchModelModal from '../modal/ResearchModelModal';
import ConfirmModal from '../modal/ConfirmModal';
import { CircularProgress } from '@mui/material';
import useComplexPrice from '../hooks/useComplexPrice';
/* eslint @typescript-eslint/explicit-function-return-type: 0 */
const useStyles = (width: number) =>
  makeStyles(() => ({
    whole_base: {
      flexDirection: 'column',
      justifyContent: 'space-between',
      backgroundColor: 'white',
      display: 'flex',
    },
    root_upper: {
      position: 'fixed',
      background: 'white',
      zIndex: '1001',
      width: '100%',
      color: '#343434',
    },
    root_lower: {
      margin: '10px',
      marginTop: `${(width * 200) / 334 + 156}px`,
    },
    root_result_lower: {
      margin: '10px',
      marginTop: `${(width * 200) / 334 + 200}px`,
    },
    selection_line_title: {
      margin: '10px',
      marginBottom: '4px',
      fontSize: '16px',
      fontWeight: 700,
      fontStyle: 'normal',
      lineHeight: '130%',
      marginLeft: '20px',
      color: '#343434',
    },
    result_title: {},
    canvas_base: {
      position: 'relative',
      width: '90vw',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    transparent_button: {
      border: 'none',
      background: 'transparent',
      marginBottom: 'auto',
      marginTop: 'auto',
    },
  }));

const useStylesPC = (width: number) =>
  makeStyles(() => ({
    whole_base: {
      flexDirection: 'column',
      justifyContent: 'space-between',
      backgroundColor: 'white',
      display: 'flex',
    },
    root_upper: {
      position: 'fixed',
      background: 'white',
      zIndex: '1001',
      width: '100%',
      color: '#343434',
    },
    root_lower: {
      margin: '10px',
      marginTop: `134px`,
      width: '30vw',
      marginLeft: 'auto',
      marginRight: '5vw',
    },
    root_result_lower: {
      marginTop: `200px`,
      marginLeft: 'auto',
      marginRight: '5vw',
      width: '30vw',
    },
    selection_line_title: {
      margin: '10px',
      marginTop: `-${(width * 200) / 334 - 10}px`,
      marginBottom: '4px',
      fontSize: '16px',
      fontWeight: 700,
      fontStyle: 'normal',
      lineHeight: '130%',
      //marginLeft: '20px',
      marginLeft: 'auto',
      marginRight: '5vw',
      color: '#343434',
      width: '30vw',
    },
    result_title: {
      marginLeft: 'auto',
      marginRight: '5vw',
      marginTop: `-${(width * 200) / 334 - 10}px`,
      width: '30vw',
    },
    canvas_base: {
      position: 'relative',
      width: `${width}px`,
      marginRight: 'auto',
      marginLeft: '30px',
    },
    transparent_button: {
      border: 'none',
      background: 'transparent',
      marginBottom: 'auto',
      marginTop: 'auto',
    },
  }));

const SelectPartsPage = () => {
  //ベースモデルの情報取得
  const selectedBaseModelData = UtilityTool.getSessionBaseModelData();
  if (selectedBaseModelData === undefined) {
    return <div></div>;
  }
  // 通信
  const filteringImageId = sessionStorage.getItem('filteringImageId');
  const [partModelDataSet, getPartModels] = usePartModels(
    selectedBaseModelData.id,
    filteringImageId === null ? 'all' : filteringImageId!!
  );
  const [itemSelections, getItemSelections] = useItemSelection();
  const [complexPriceSet, getComplexPriceSet] = useComplexPrice(selectedBaseModelData.id);

  // 最初にページに到達してきた時のみローディングする。
  useEffect(() => {
    getPartModels()
      .catch(() => {})
      .then(() => {})
      .catch(() => 'obligatory catch');
    getItemSelections()
      .catch(() => {})
      .then(() => {})
      .catch(() => 'obligatory catch');
    getComplexPriceSet()
      .catch(() => {})
      .then(() => {})
      .catch(() => 'obligatory catch');
  }, []);

  let screenWidth = document.documentElement.clientWidth * 0.9;

  const [aa, setA] = useState<number>(document.documentElement.clientWidth);

  const classesForResponsive = (isSideways: boolean) => {
    if (isSideways) {
      return useStyles(aa * 0.9)();
    } else {
      screenWidth = document.documentElement.clientWidth * 0.6;
      return useStylesPC(aa * 0.6)();
    }
  };
  useEffect(() => {
    setA(document.documentElement.clientWidth);
  }, [document.documentElement.clientWidth]);

  const [researchModalOpen, setResearchModalOpen] = useState<boolean>(false);

  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);

  // 食洗機
  const [selectedWasher, setSelectedWasher, washerList] = useSinglePartModel(
    partModelDataSet,
    itemSelections,
    'type_washer'
  );
  //加熱機器
  const [selectedHeating, setSelectedHeating, heatingList] = useSinglePartModel(
    partModelDataSet,
    itemSelections,
    'type_heating'
  );

  // レンジフード
  const [selectedRange, setSelectedRange, rangeList] = useSinglePartModel(
    partModelDataSet,
    itemSelections,
    'type_range'
  );

  // 天板
  const [selectedTabletop, setSelectedTabletop, tabletopList] = useLayer1PartModels(
    partModelDataSet,
    itemSelections,
    'type_wet_area'
  );

  // シンク
  const [selectedSink, setSelectedSink, sinkList, setSinkList] = useLayer2PartModels(
    partModelDataSet,
    itemSelections,
    'type_wet_area',
    selectedTabletop
  );

  // 水栓
  const [selectedTap, setSelectedTap, tapList, setTapList] = useLayer3PartModels(
    partModelDataSet,
    itemSelections,
    'type_wet_area',
    selectedTabletop,
    selectedSink
  );

  // 扉デザイン
  const [selectedDoorDesign, setSelectedDoorDesign, doorDesignList] = useLayer1PartModels(
    partModelDataSet,
    itemSelections,
    'type_door_area'
  );

  // 扉色
  const [selectedDoorColor, setSelectedDoorColor, doorColorList, setDoorColorList] =
    useLayer2PartModels(partModelDataSet, itemSelections, 'type_door_area', selectedDoorDesign);

  // 取手
  const [selectedDoorGrip, setSelectedDoorGrip, doorGripList, setDoorGripList] =
    useLayer3PartModels(
      partModelDataSet,
      itemSelections,
      'type_door_area',
      selectedDoorDesign,
      selectedDoorColor
    );

  // ページのステータスに関するステータス。
  const [isResultPage, setIsResultPage] = useState<boolean>(false);

  // アップロードが済んでいるかどうか
  const [isUploadDone, setIsUploadDone] = useState<boolean>(false);

  //モデルのダウンロードが済んでいるかどうか
  const [isDownloadDone, setIsDownLoadDone] = useState<boolean>(false);

  window.addEventListener('popstate', function (e) {
    if (isResultPage) {
      setIsResultPage(false);
      sessionStorage.setItem('isResultPage', 'false');
      sessionStorage.setItem('isUploadDone', 'false');
      setIsUploadDone(false);
    } else {
    }
  });

  //ページが変わるごとにスクロール位置を初期位置に戻す。
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, [isResultPage]);

  // 結果ページでリロードを挟んだ時にisResultを保持する。
  const res = sessionStorage.getItem('isResultPage');
  if (res === 'true' && !isResultPage) {
    setIsResultPage(true);
  }
  if (res === 'false' && isResultPage) {
    setIsResultPage(false);
  }

  // 結果ページでリロードを挟んだ時にisUploadDoneを保持する。
  const resIsUploadDone = sessionStorage.getItem('isUploadDone');
  if (resIsUploadDone === 'true' && !isUploadDone) {
    setIsUploadDone(true);
  }
  if (resIsUploadDone === 'false' && isUploadDone) {
    setIsUploadDone(false);
  }

  // 詳細説明文に関するステート
  const [explainPartModel, setExplainPartModel] = useState<PartModelData>(
    UtilityTool.getEmptyPartModelData()
  );
  const [openExplainModal, setOpenExplainModal] = useState<boolean>(false);
  const [renderingModelsReq, setRenderingModelsReq] = useState<RenderingModelsOrder | undefined>(
    undefined
  );

  // 縦型では対応していない旨を通知するステート

  const initialOpenAlertModal = (): boolean => {
    if (UtilityTool.isSmartPhone()) {
      return window.orientation !== 0;
    } else {
      return false;
    }
  };
  const [openAlertModal, setOpenAlertModal] = useState<boolean>(initialOpenAlertModal);
  const [isSideways, setIsSideWays] = useState<boolean>(window.orientation === 0);
  window.addEventListener('orientationchange', () => {
    const orientation = window.orientation;
    if (orientation !== 0) {
      if (!UtilityTool.isTablet()) {
        setOpenAlertModal(true);
      }
      setIsSideWays(false);
    } else {
      setOpenAlertModal(false);
      setIsSideWays(true);
    }
  });

  // カメラ位置の調整
  /* eslint  @typescript-eslint/no-unused-vars: 0 */
  const [valueX, setValueX] = useState<number>(50);

  const [isFirstRendering, setIsFirstRendering] = useState<boolean>(true);

  // データの格納後、初回の設定を行う。
  useEffect(() => {
    // 通信が届いていない初期は何も処理しない
    if (selectedDoorDesign.id === '') return;
    if (selectedDoorColor.id === '') return;
    if (selectedDoorGrip.id === '') return;
    if (selectedRange.id === '') return;
    if (selectedWasher.id === '') return;
    if (selectedHeating.id === '') return;
    if (selectedTabletop.id === '') return;
    if (selectedSink.id === '') return;
    if (selectedTap.id === '') return;

    if (isFirstRendering) {
      setIsFirstRendering(false);
      /*ここで初期設定 */
      //セッションデータへの格納を行う
      sessionStorage.setItem('washerId', selectedWasher.id);
      sessionStorage.setItem('rangeId', selectedRange.id);
      sessionStorage.setItem('heatingId', selectedHeating.id);
      sessionStorage.setItem('tabletopId', selectedTabletop.id);
      sessionStorage.setItem('sinkId', selectedSink.id);
      sessionStorage.setItem('tapId', selectedTap.id);
      sessionStorage.setItem('doorDesign', selectedDoorDesign.id);
      sessionStorage.setItem('doorColor', selectedDoorColor.id);
      sessionStorage.setItem('doorGrip', selectedDoorGrip.id);

      createRenderingOrder(
        selectedWasher,
        selectedHeating,
        selectedRange,
        selectedTabletop,
        selectedSink,
        selectedTap,
        selectedDoorDesign,
        selectedDoorColor,
        selectedDoorGrip
      );
    }
  }, [
    selectedDoorDesign,
    selectedDoorColor,
    selectedDoorGrip,
    selectedRange,
    selectedWasher,
    selectedHeating,
    selectedTabletop,
    selectedSink,
    selectedTap,
  ]);
  const onClickExplanation = (partModel: PartModelData) => {
    setExplainPartModel(partModel);
    setOpenExplainModal(true);
  };

  const onClickSelect = (
    selectedModel: PartModelData,
    setValue: (value: React.SetStateAction<PartModelData>) => void
  ) => {
    setValue(selectedModel);
    /* eslint  prefer-const: 0 */
    let ini = {
      washer: selectedWasher,
      heating: selectedHeating,
      range: selectedRange,
      tabletop: selectedTabletop,
      sink: selectedSink,
      tap: selectedTap,
      doorDesign: selectedDoorDesign,
      doorColor: selectedDoorColor,
      doorGrip: selectedDoorGrip,
    };
    switch (selectedModel.item_type_name) {
      case 'type_washer':
        ini.washer = selectedModel;
        sessionStorage.setItem('washerId', ini.washer.id);
        break;
      case 'type_heating':
        ini.heating = selectedModel;
        sessionStorage.setItem('heatingId', ini.heating.id);
        break;
      case 'type_tap':
        ini.tap = selectedModel;
        break;
      case 'type_range':
        ini.range = selectedModel;
        sessionStorage.setItem('rangeId', ini.range.id);
        break;
      case 'type_door_grip':
        ini.doorGrip = selectedModel;
        break;
      case 'type_door_design':
        ini.doorDesign = selectedModel;
        ini.doorColor = UtilityWithSelection.updateLayer2List(
          partModelDataSet,
          itemSelections,
          'type_door_area',
          selectedModel,
          setSelectedDoorColor,
          setDoorColorList
        )[0];
        ini.doorGrip = UtilityWithSelection.updateLayer3List(
          partModelDataSet,
          itemSelections,
          'type_door_area',
          selectedModel,
          ini.doorColor,
          setSelectedDoorGrip,
          setDoorGripList
        )[0];
        break;
      case 'type_door_color':
        ini.doorColor = selectedModel;
        ini.doorGrip = UtilityWithSelection.updateLayer3List(
          partModelDataSet,
          itemSelections,
          'type_door_area',
          selectedDoorDesign,
          selectedModel,
          setSelectedDoorGrip,
          setDoorGripList
        )[0];
        break;
      case 'type_tabletop':
        ini.tabletop = selectedModel;
        ini.sink = UtilityWithSelection.updateLayer2List(
          partModelDataSet,
          itemSelections,
          'type_wet_area',
          selectedModel,
          setSelectedSink,
          setSinkList
        )[0];
        ini.tap = UtilityWithSelection.updateLayer3List(
          partModelDataSet,
          itemSelections,
          'type_wet_area',
          selectedModel,
          ini.sink,
          setSelectedTap,
          setTapList
        )[0];
        break;
      case 'type_sink':
        ini.sink = selectedModel;
        ini.tap = UtilityWithSelection.updateLayer3List(
          partModelDataSet,
          itemSelections,
          'type_wet_area',
          selectedTabletop,
          selectedModel,
          setSelectedTap,
          setTapList
        )[0];
        break;
      default:
        break;
    }
    //セッションに情報を保存
    sessionStorage.setItem('tabletopId', ini.tabletop.id);
    sessionStorage.setItem('sinkId', ini.sink.id);
    sessionStorage.setItem('tapId', ini.tap.id);
    sessionStorage.setItem('doorDesign', ini.doorDesign.id);
    sessionStorage.setItem('doorColor', ini.doorColor.id);
    sessionStorage.setItem('doorGrip', ini.doorGrip.id);
    createRenderingOrder(
      ini.washer,
      ini.heating,
      ini.range,
      ini.tabletop,
      ini.sink,
      ini.tap,
      ini.doorDesign,
      ini.doorColor,
      ini.doorGrip
    );
  };

  const createRenderingOrder = (
    washer: PartModelData,
    heating: PartModelData,
    range: PartModelData,
    tabletop: PartModelData,
    sink: PartModelData,
    tap: PartModelData,
    doorDesign: PartModelData,
    doorColor: PartModelData,
    doorGrip: PartModelData
  ) => {
    if (tabletop === undefined) return;
    if (sink === undefined) return;
    if (tap === undefined) return;
    if (doorDesign === undefined) return;
    if (doorColor === undefined) return;
    if (doorGrip === undefined) return;
    if (washer === undefined) return;
    if (range === undefined) return;
    if (heating === undefined) return;
    setRenderingModelsReq({
      baseModel: selectedBaseModelData,
      wet_area: UtilityWithSelection.integrateAreaModel(
        tabletop.id,
        sink.id,
        tap.id,
        'type_wet_area',
        partModelDataSet
      ),
      door_area: UtilityWithSelection.integrateAreaModel(
        doorDesign.id,
        doorColor.id,
        doorGrip.id,
        'type_door_area',
        partModelDataSet
      ),
      washer: washer,
      range: range,
      heating: heating,
      door_color_id: doorColor.id,
    });
  };

  return (
    <div>
      <Header openModal={setResearchModalOpen} />
      <div className={classesForResponsive(isSideways).whole_base}>
        <div>
          <div id="selectPageUpper" className={classesForResponsive(isSideways).root_upper}>
            {isResultPage ? <ResultPageTitle /> : <SelectPageTitle />}
            <div className={classesForResponsive(isSideways).canvas_base}>
              <canvas id="simulateCanvas" style={{ position: 'relative' }}></canvas>
              {isDownloadDone ? (
                <div>
                  <div
                    style={{
                      position: 'absolute',
                      top: '0px',
                      right: '0px',
                      background: 'rgba(63, 63, 63, 0.5)',
                      width: '100%',
                      height: '100%',
                    }}
                  ></div>
                  <div style={{ position: 'absolute', top: '40%', left: '44%' }}>
                    <CircularProgress
                      style={{
                        color: 'rgb(255 255 255)',
                        marginLeft: '0px',
                        position: 'absolute',
                        width: '10vw',
                        height: '10vw',
                      }}
                      //size={64}
                    />
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
            {isResultPage ? (
              <div className={classesForResponsive(isSideways).result_title}>
                <div style={{ fontSize: '10px', marginLeft: '20px', width: '90% ' }}>
                  <div>
                    {
                      ' ※表示されている3Dモデル・ARイメージは、実際の商品と若干異なる場合がございます。'
                    }
                  </div>
                </div>
                <ARResultSection isUploadDone={isUploadDone} />
              </div>
            ) : (
              <div
                className={classesForResponsive(isSideways).selection_line_title}
              >{`キッチンの仕様(${selectedBaseModelData.name})`}</div>
            )}
          </div>
          {isResultPage ? (
            <div className={classesForResponsive(isSideways).root_result_lower}>
              <RootLowerResult
                selectedBaseModel={selectedBaseModelData}
                selectedDoorDesign={selectedDoorDesign}
                selectedDoorColor={selectedDoorColor}
                selectedDoorGrip={selectedDoorGrip}
                selectedTabletop={selectedTabletop}
                selectedSink={selectedSink}
                selectedTap={selectedTap}
                selectedWasher={selectedWasher}
                selectedHeating={selectedHeating}
                selectedRange={selectedRange}
              />
            </div>
          ) : (
            <div className={classesForResponsive(isSideways).root_lower}>
              <PartModelsLine
                selectedDoorDesign={selectedDoorDesign}
                _doorDesignList={doorDesignList}
                setSelectedDoorDesign={setSelectedDoorDesign}
                selectedDoorColor={selectedDoorColor}
                _doorColorList={doorColorList}
                setSelectedDoorColor={setSelectedDoorColor}
                selectedDoorGrip={selectedDoorGrip}
                _doorGripList={doorGripList}
                setSelectedDoorGrip={setSelectedDoorGrip}
                selectedTabletop={selectedTabletop}
                _tabletopList={tabletopList}
                setSelectedTabletop={setSelectedTabletop}
                selectedSink={selectedSink}
                _sinkList={sinkList}
                setSelectedSink={setSelectedSink}
                selectedTap={selectedTap}
                _tapList={tapList}
                setSelectedTap={setSelectedTap}
                selectedWasher={selectedWasher}
                _washerList={washerList}
                setSelectedWasher={setSelectedWasher}
                selectedHeating={selectedHeating}
                _heatingList={heatingList}
                setSelectedHeating={setSelectedHeating}
                selectedRange={selectedRange}
                _rangeList={rangeList}
                setSelectedRange={setSelectedRange}
                onClickSelect={onClickSelect}
                onClickExplanation={onClickExplanation}
              />
            </div>
          )}
          <PartModelExplainModal
            partsModel={explainPartModel}
            open={openExplainModal}
            handleClose={() => {
              setOpenExplainModal(false);
            }}
          />
          <HeightWidthModal
            open={openAlertModal}
            handleClose={() => {
              setOpenAlertModal(false);
            }}
          />
          <ResearchModelModal
            open={researchModalOpen}
            handleClose={() => {
              setResearchModalOpen(false);
            }}
          />
          <ConfirmModal
            open={confirmModalOpen}
            handleClose={() => {
              setConfirmModalOpen(false);
            }}
          />
          <Simulator
            req={renderingModelsReq}
            cameraPositionX={valueX}
            screenWidth={screenWidth}
            setIsDownLoadDone={setIsDownLoadDone}
          />
          {isResultPage ? (
            <ResultFooter
              price={UtilityTool.calculateCost(renderingModelsReq, complexPriceSet)}
              setPageState={setIsResultPage}
              setIsUploadDone={setIsUploadDone}
              setConfirmModal={setConfirmModalOpen}
            />
          ) : (
            <PartModelFooter
              price={UtilityTool.calculateCost(renderingModelsReq, complexPriceSet)}
              setPageState={setIsResultPage}
              setIsUploadDone={setIsUploadDone}
              partModelDataSet={partModelDataSet}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectPartsPage;
