import React from 'react';
import { makeStyles } from '@mui/styles';

interface Props {
  titleIconPath: string;
  titleText: string;
  titleTextSecond?: string;
}

const useStyles = makeStyles(() => ({
  page_title: {
    display: 'flex',
    marginTop: '55px',
    marginBottom: '10px',
    marginLeft: '20px',
    height: '48px',
  },
  page_title_message: {
    margin: 6,
    marginTop: 'auto',
    marginBottom: 'auto',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '130%',
  },
  page_title_icon: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
}));

const CommonPageTitle = (props: Props): JSX.Element => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.page_title}>
        <img
          src={props.titleIconPath}
          width={38}
          height={39}
          className={classes.page_title_icon}
        ></img>
        <div className={classes.page_title_message}>
          <div>{props.titleText}</div>
          <div>{props.titleTextSecond}</div>
        </div>
      </div>
    </div>
  );
};

export default CommonPageTitle;
