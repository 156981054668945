import axios, { AxiosResponse } from 'axios';
import { APP_END_POINT } from '../environment/environment';
import ItemSelectionData from '../types/copied/dto/data/ItemSelectionData';
/**
 * 複合アイテムの選択肢を管理するためのservice層
 */
/* eslint-disable */
export class ItemSelectionService {
  /* eslint-disable */
  /**
   * 全部の複合アイテム情報の取得
   */
  public static getAllItemSelections = async (): Promise<ItemSelectionData[] | undefined> => {
    const requestURL = APP_END_POINT + '/item_selection';
    let res;
    await axios
      .get(requestURL, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
      .then((response: AxiosResponse<any>) => {
        //console.log('response: ', response.data.body);
        res = response.data.body;
      })
      // catchでエラー時の挙動を定義
      .catch((err) => {
        //console.log('err:', err);
      });
    return res;
  };
}
