import React, { FC } from 'react';
import { makeStyles } from '@mui/styles';

interface Props {
  isEnable: boolean;
  text: string;
  isUnionIcon?: boolean;
}

const useStyles = makeStyles((theme) => ({
  enable_button: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '120px',
    height: '40px',
    background: '#FFFFFF',
    border: ' 1px solid #343434',
    borderRadius: '24px',
    color: '#343434',
    fontWeight: '700',
    fontSize: '16px',
    cursor: 'pointer',
  },
  disable_button: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '120px',
    height: '40px',
    background: '#E0E0EB',
    border: ' 1px solid #939393',
    borderRadius: '24px',
    color: '#A4A5B0',
    fontWeight: '700',
    fontSize: '16px',
  },
  union_icon_img: {
    marginTop: '1px',
    marginBottom: '3px',
    height: '20px',
    width: '20px',
  },
}));

const IconBase: FC<Props> = (props: Props) => {
  const classes = useStyles();
  return (
    <div>
      {props.isEnable ? (
        <div className={classes.enable_button}>
          {props.isUnionIcon === true ? (
            <div
              style={{ display: 'flex', marginTop: 'auto', marginBottom: 'auto', height: '20px' }}
            >
              {props.text}
              <div className={classes.union_icon_img}>
                <img
                  src="svg/Union.svg"
                  width={20}
                  height={20}
                  style={{ marginTop: 'auto', marginBottom: 'auto' }}
                />
              </div>
            </div>
          ) : (
            <div style={{ display: 'flex', marginTop: 'auto', marginBottom: 'auto' }}>
              {props.text}
            </div>
          )}
        </div>
      ) : (
        <div className={classes.disable_button}>
          <div>{props.text}</div>
        </div>
      )}
    </div>
  );
};

export default IconBase;
