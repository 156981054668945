import React, { FC } from 'react';
import { makeStyles } from '@mui/styles';
import PartModelData from '../../../types/copied/dto/data/PartModelData';
import BaseModelData from '../../../types/copied/dto/data/BaseModelData';
import CommonPartCheckSection from '../common/CommonPartCheckSection';
import CommonBaseCheckSection from '../common/CommonBaseCheckSection';

interface Props {
  selectedBaseModel: BaseModelData;
  selectedDoorDesign: PartModelData;
  selectedDoorColor: PartModelData;
  selectedDoorGrip: PartModelData;
  selectedTabletop: PartModelData;
  selectedSink: PartModelData;
  selectedTap: PartModelData;
  selectedWasher: PartModelData;
  selectedRange: PartModelData;
  selectedHeating: PartModelData;
}

const useStyles = makeStyles(() => ({
  section_base: {
    marginLeft: '10px',
    marginRight: '10px',
  },
  section_title: {
    background: '#D9D9D9',
    padding: '8px',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '0.8rem',
    lineHeight: '130%',
  },
}));

const RootLowerResult: FC<Props> = (props: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.section_base}>
        <div style={{ marginTop: '10px' }}></div>
        <div className={classes.section_title}>{'基本'}</div>
        <CommonBaseCheckSection
          titleName="形状"
          details={props.selectedBaseModel.name}
          thumbnail={props.selectedBaseModel.thumbnail_url}
        />
      </div>
      <div className={classes.section_base}>
        <div className={classes.section_title}>{'本体'}</div>
        <CommonPartCheckSection
          itemTypeTitleName="扉 / 樹種"
          _targetModel={props.selectedDoorDesign}
        />
        <CommonPartCheckSection itemTypeTitleName="カラー" _targetModel={props.selectedDoorColor} />
        <CommonPartCheckSection itemTypeTitleName="取手" _targetModel={props.selectedDoorGrip} />
        <CommonPartCheckSection itemTypeTitleName="天板" _targetModel={props.selectedTabletop} />
        <CommonPartCheckSection itemTypeTitleName="シンク" _targetModel={props.selectedSink} />
        <CommonPartCheckSection itemTypeTitleName="水栓" _targetModel={props.selectedTap} />
        <CommonPartCheckSection itemTypeTitleName="食洗機" _targetModel={props.selectedWasher} />
        <CommonPartCheckSection
          itemTypeTitleName="レンジフード"
          _targetModel={props.selectedRange}
        />
        <CommonPartCheckSection itemTypeTitleName="加熱機器" _targetModel={props.selectedHeating} />
      </div>
    </div>
  );
};

export default RootLowerResult;
