import { ItemSelectionService } from '../services/ItemSelectionService';
import ItemSelectionData from '../types/copied/dto/data/ItemSelectionData';

/**
 * 複合アイテムに関するユースケース層
 */
export class ItemSelectionUsecase {
  /**
   * 全複合アイテムの取得
   */
  public static getItemSelections = async (): Promise<ItemSelectionData[]> => {
    const res = await ItemSelectionService.getAllItemSelections();
    if (res === undefined) return [];
    return res;
  };
}
