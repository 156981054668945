export class UtilityWithSession {
  public static clearSessionInfo = () => {
    sessionStorage.removeItem('washerId');
    sessionStorage.removeItem('heatingId');
    sessionStorage.removeItem('rangeId');
    sessionStorage.removeItem('tabletopId');
    sessionStorage.removeItem('sinkId');
    sessionStorage.removeItem('tapId');
    sessionStorage.removeItem('doorDesign');
    sessionStorage.removeItem('doorColor');
    sessionStorage.removeItem('doorGrip');
    sessionStorage.removeItem('baseModelId');
    sessionStorage.removeItem('baseModelUrl');
    sessionStorage.removeItem('baseModelName');
    sessionStorage.removeItem('baseModelPrice');
    sessionStorage.removeItem('baseModelThumbnailUrl');
  };
}
