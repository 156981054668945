import { ComplexPriceService } from '../services/ComplexPriceService';
import ComplexPriceData from '../types/copied/dto/data/ComplexPriceData';

/**
 * 複合価格に関するユースケース層
 */
export class ComplexPriceUsecase {
  /**
   * 該当複合価格の取得
   */
  public static getComplexPriceSet = async (baseModelId: string): Promise<ComplexPriceData[]> => {
    const res = await ComplexPriceService.getComplexPriceDataSet(baseModelId);
    if (res === undefined) return [];
    return res;
  };
}
