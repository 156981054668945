import React from 'react';
import CommonFooter from '../common/CommonFooter';

interface Props {
  // eslint-disable-line
  price: string;
  setPageState: (value: React.SetStateAction<boolean>) => void;
  setIsUploadDone: (value: React.SetStateAction<boolean>) => void;
  setConfirmModal: (value: React.SetStateAction<boolean>) => void;
}

const ResultFooter = (props: Props): JSX.Element => {
  const onClickBackButton = (): void => {
    props.setPageState(false);
    sessionStorage.setItem('isResultPage', 'false');
    sessionStorage.setItem('isUploadDone', 'false');
    props.setIsUploadDone(false);
  };

  const onClickToReservationButton = (): void => {
    props.setConfirmModal(true);
  };

  return (
    <CommonFooter
      canMoveToNextPage={true}
      onClickBack={onClickBackButton}
      onClickNext={onClickToReservationButton}
      ableBackButton={true}
      ablePriceText={true}
      price={props.price}
      nextButtonText="予約へ進む"
      isUnionIcon={true}
    />
  );
};

export default ResultFooter;
