import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import FooterNextIconImage from './FooterNextIconImage';

interface Props {
  canMoveToNextPage: boolean;
  onClickNext: () => void;
  onClickBack: () => void;
  ableBackButton?: boolean;
  ablePriceText?: boolean;
  price?: string;
  nextButtonText: string;
  isUnionIcon?: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '56px',
    display: 'flex',
    justifyContent: 'center',
  },
  footer_base: {
    position: 'fixed',
    display: 'flex',
    backgroundColor: 'rgba(52, 52, 52, 0.9)',
    justifyContent: 'center',
    alignItems: 'center',
    height: '56px',
    width: '95%',
    bottom: 0,
    borderRadius: '32px',
    marginRight: 'auto',
    marginLeft: 'auto',
    zIndex: '700',
  },
  footer_title: {
    color: 'white',
    marginRight: '0px',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: '12px',
    textAlign: 'center',
  },
  price: {
    color: 'white',
    margin: 1,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '22px',
    textAlign: 'right',
  },
  transparent_button: {
    border: 'none',
    background: 'transparent',
    position: 'absolute',
    right: '16px',
  },
  transparent_button_back: {
    border: 'none',
    background: 'transparent',
    paddingRight: '0px',
    position: 'absolute',
    right: '160px',
    padding: '2px',
    cursor: 'pointer',
  },
  back_icon_img: { width: '42px', height: '26px' },
  price_base: {
    position: 'absolute',
    left: '24px',
  },
}));

const useStylesPC = makeStyles(() => ({
  root: {
    width: '100%',
    height: '56px',
    display: 'flex',
    justifyContent: 'center',
  },
  footer_base: {
    position: 'fixed',
    display: 'flex',
    backgroundColor: 'rgba(52, 52, 52, 0.9)',
    justifyContent: 'center',
    alignItems: 'center',
    height: '56px',
    width: '95%',
    bottom: 0,
    borderRadius: '32px',
    marginRight: 'auto',
    marginLeft: 'auto',
    zIndex: '700',
    maxWidth: '540px',
  },
  footer_title: {
    color: 'white',
    marginRight: '0px',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: '12px',
    textAlign: 'center',
  },
  price: {
    color: 'white',
    margin: 1,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '22px',
    textAlign: 'right',
  },
  transparent_button: {
    border: 'none',
    background: 'transparent',
    position: 'absolute',
    right: '16px',
  },
  transparent_button_back: {
    border: 'none',
    background: 'transparent',
    paddingRight: '0px',
    position: 'absolute',
    left: '40px',
    padding: '2px',
    cursor: 'pointer',
  },
  back_icon_img: { width: '42px', height: '26px' },
  price_base: {
    position: 'absolute',
    right: '160px',
  },
}));

const CommonFooter = (props: Props): JSX.Element => {
  const classes = useStyles();
  const classesPC = useStylesPC();
  /*
  if (navigator.userAgent.match(/(iPhone|iPod|Android)/i)) {
    // スマホの場合の配列
  } else {
    // pcの場合の配列
    classes = useStylesPC();
  }
  */
  const classesForResponsive = (_isSideways: boolean) => {
    if (_isSideways) {
      return classes;
    } else {
      return classesPC;
    }
  };

  const [isSideways, setIsSideWays] = useState<boolean>(window.orientation === 0);

  window.addEventListener('orientationchange', () => {
    const orientation = window.orientation;
    if (orientation !== 0) {
      setIsSideWays(false);
    } else {
      setIsSideWays(true);
    }
  });

  const onClickBackButton = (): void => {
    props.onClickBack();
  };

  const onClickConfirmButton = (): void => {
    props.onClickNext();
  };

  return (
    <div className={classesForResponsive(isSideways).root}>
      <div className={classesForResponsive(isSideways).footer_base}>
        {props.ablePriceText === true ? (
          <div className={classesForResponsive(isSideways).price_base}>
            <div className={classesForResponsive(isSideways).footer_title}>
              {'キッチン本体価格(税込)'}
            </div>
            <div className={classesForResponsive(isSideways).price}>
              {Number(props.price).toLocaleString() + '円'}
            </div>
          </div>
        ) : (
          <></>
        )}
        <div style={{ width: '118px', height: '40px' }}></div>
        {props.ableBackButton === true ? (
          <button
            onClick={onClickBackButton}
            className={classesForResponsive(isSideways).transparent_button_back}
          >
            <img
              src="svg/back_page_button.svg"
              className={classesForResponsive(isSideways).back_icon_img}
            />
          </button>
        ) : (
          <></>
        )}
        <button
          onClick={onClickConfirmButton}
          className={classesForResponsive(isSideways).transparent_button}
          disabled={!props.canMoveToNextPage}
        >
          <FooterNextIconImage
            isEnable={props.canMoveToNextPage}
            text={props.nextButtonText}
            isUnionIcon={props.isUnionIcon}
          />
        </button>
      </div>
    </div>
  );
};

export default CommonFooter;
