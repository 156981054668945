import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import FilteringImageData from '../../types/copied/dto/data/FilteringImageData';
import FilteringImageFooter from '../components/footer/FilteringImageFooter';
import Header from '../components/header/Header';
import FilteringImageSelectLine from '../components/contents/FilteringImageSelectLine';
import FilteringImagePageTitle from '../components/title/FilteringImagePageTitle';
import ResearchModelModal from '../modal/ResearchModelModal';
import HeightWidthModal from '../modal/HeightWidthModal';
import { UtilityTool } from '../../utility/UtilityTool';
import useFilteringImage from '../hooks/useFilteringImage';

const useStyles = makeStyles(() => ({
  whole_base: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    display: 'flex',
  },
  root_upper: {
    position: 'fixed',
    background: 'white',
    zIndex: '1001',
    width: '100%',
    color: '#343434',
  },
  root_lower: {
    marginTop: '112px',
  },
}));

const FilteringImagePage = (): JSX.Element => {
  const classes = useStyles();
  //通信
  const [filteringImageList, getFilteringImageList] = useFilteringImage();
  const testData1: FilteringImageData = {
    id: 'filtering_image_id_1',
    name: '',
    image_url: '/thumbnail/filtering_image/filtering_image_id_1.jpg',
  };
  const testData2: FilteringImageData = {
    id: 'filtering_image_id_2',
    name: '',
    image_url: '/thumbnail/filtering_image/filtering_image_id_2.jpg',
  };
  const testData3: FilteringImageData = {
    id: 'filtering_image_id_3',
    name: '',
    image_url: '/thumbnail/filtering_image/filtering_image_id_3.jpg',
  };

  const [researchModalOpen, setResearchModalOpen] = useState<boolean>(false);
  const [selectedFilteringImage, setSelectedFilteringImage] = useState<FilteringImageData>();

  const initialOpenAlertModal = (): boolean => {
    if (UtilityTool.isSmartPhone()) {
      return window.orientation !== 0;
    } else {
      return false;
    }
  };
  const [openAlertModal, setOpenAlertModal] = useState<boolean>(initialOpenAlertModal);

  window.addEventListener('orientationchange', () => {
    const orientation = window.orientation;
    if (orientation !== 0) {
      if (!UtilityTool.isTablet()) {
        setOpenAlertModal(true);
      } else {
        setOpenAlertModal(false);
      }
    } else {
      setOpenAlertModal(false);
    }
  });

  useEffect(() => {
    getFilteringImageList()
      .catch(() => {})
      .then(() => {})
      .catch(() => 'obligatory catch');
  }, []);
  return (
    <div className={classes.whole_base}>
      <Header openModal={setResearchModalOpen} />
      <div>
        <div className={classes.root_upper}>
          <FilteringImagePageTitle />
        </div>
        <div>
          <div className={classes.root_lower}>
            {
              <FilteringImageSelectLine
                selectedFilteringImage={selectedFilteringImage}
                selectList={filteringImageList}
                setValue={setSelectedFilteringImage}
              />
            }
          </div>
          <ResearchModelModal
            open={researchModalOpen}
            handleClose={() => {
              setResearchModalOpen(false);
            }}
          />
          <HeightWidthModal
            open={openAlertModal}
            handleClose={() => {
              setOpenAlertModal(false);
            }}
          />
          <FilteringImageFooter isSelectedFilteringImage={selectedFilteringImage !== undefined} />
        </div>
      </div>
    </div>
  );
};

export default FilteringImagePage;
