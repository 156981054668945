import { useState } from 'react';
import FilteringImageData from '../../types/copied/dto/data/FilteringImageData';
import { FilteringImageUsecase } from '../../usecases/FilteringImageUsecase';

/**
 * ベースモデルを管理するためのhooks
 */

const useFilteringImage = (): [FilteringImageData[], () => Promise<void>] => {
  const [filteringImages, setFilteringImages] = useState<FilteringImageData[]>([]);
  /**
   * フィルタリング画像取得
   */
  const getBaseModels = async (): Promise<void> => {
    const res = await FilteringImageUsecase.getFilteringImage();
    const sortedRes1 = res.sort(function (a, b) {
      if (a.id > b.id) {
        return 1;
      } else {
        return -1;
      }
    });
    if (res.length !== 0) setFilteringImages(sortedRes1);
  };

  return [filteringImages, getBaseModels];
};

export default useFilteringImage;
