import axios, { AxiosResponse } from 'axios';
import { APP_END_POINT } from '../environment/environment';
import ComplexPriceData from '../types/copied/dto/data/ComplexPriceData';
/**
 * 複合価格を管理するためのservice層
 */
/* eslint-disable */
export class ComplexPriceService {
  /* eslint-disable */
  public static getComplexPriceDataSet = async (
    baseModelId: string
  ): Promise<ComplexPriceData[] | undefined> => {
    const requestURL = APP_END_POINT + `/complex_price/${baseModelId}`;
    let res;
    await axios
      .get(requestURL, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((response: AxiosResponse<any>) => {
        // console.log('response: ', response.data.body);
        res = response.data.body;
        return res;
      })
      // catchでエラー時の挙動を定義
      .catch((err) => {
        //console.log('err:', err);
      });
    return res;
  };
}
