import { useEffect, useState } from 'react';
import ItemSelectionData from '../../types/copied/dto/data/ItemSelectionData';
import PartModelData from '../../types/copied/dto/data/PartModelData';
import { UtilityTool } from '../../utility/UtilityTool';
import { UtilityWithSelection } from '../../utility/UtilityWithSelection';

/**
 * 選択されたパーツモデルを管理するためのhooks
 */

const useSinglePartModel = (
  partModelDataSet: PartModelData[],
  itemSelections: ItemSelectionData[],
  typeName: string
): [PartModelData, (value: React.SetStateAction<PartModelData>) => void, PartModelData[]] => {
  const list = UtilityWithSelection.extractSingleList(typeName, partModelDataSet);
  const [selectedItem, setSelectedItem] = useState<PartModelData>(
    UtilityTool.getEmptyPartModelData()
  );

  const sessionId = () => {
    if (typeName === 'type_washer') {
      return 'washerId';
    }
    if (typeName === 'type_range') {
      return 'rangeId';
    }
    if (typeName === 'type_heating') {
      return 'heatingId';
    }
    return '';
  };
  // データベースからの情報が届いた時に実行する
  useEffect(() => {
    if (partModelDataSet.length === 0) {
      return;
    }
    if (sessionStorage.getItem('tapId') === null) {
      setSelectedItem(list[0]);
    } else {
      const SinglePartModel = UtilityTool.getByID(sessionStorage.getItem(sessionId()), list);
      setSelectedItem(SinglePartModel);
    }
  }, [partModelDataSet.length, itemSelections.length]);

  return [selectedItem, setSelectedItem, list];
};

export default useSinglePartModel;
