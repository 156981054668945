import * as THREE from 'three';

/**
 * THREE.WebGLRendererのラッパー
 * (Data内の情報を元に生成する拡張クラス)
 * @param {Element} canvas Canvas要素
 */
class RendererWrapper extends THREE.WebGLRenderer {
  constructor(canvas: HTMLElement) {
    super({
      canvas,
      preserveDrawingBuffer: true,
      antialias: true,
    });
    this.physicallyCorrectLights = true;
    this.outputEncoding = THREE.sRGBEncoding;
    this.toneMapping = THREE.ReinhardToneMapping;
    this.shadowMap.enabled = true;
    this.shadowMap.type = THREE.PCFSoftShadowMap;
    this.setPixelRatio(window.devicePixelRatio);
    this.setSize(window.innerWidth, window.innerHeight);
  }
}

export default RendererWrapper;
