import React, { useState } from 'react';
import CommonPageTitle from '../common/CommonPageTitle';

const FilteringImagePageTitle = (): JSX.Element => {
  const [isSideways, setIsSideWays] = useState<boolean>(window.orientation === 0);
  window.addEventListener('orientationchange', () => {
    const orientation = window.orientation;
    if (orientation !== 0) {
      setIsSideWays(false);
    } else {
      setIsSideWays(true);
    }
  });
  return (
    <div>
      {!isSideways ? (
        <CommonPageTitle
          titleIconPath="svg/FrameStep01.svg"
          titleText="イメージに近い画像を選択してください"
        />
      ) : (
        <CommonPageTitle
          titleIconPath="svg/FrameStep01.svg"
          titleText="キッチンのイメージに近い"
          titleTextSecond="画像を選択してください"
        />
      )}
    </div>
  );
};

export default FilteringImagePageTitle;
