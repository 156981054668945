import axios from 'axios';

/**
 * S3へのアクセスを担当するためのクラス
 */
class s3Adapter {
  /**
   * S3にデータを配置するための関数
   * @param file
   * @returns s3にするための関数
   */
  public static putObject = async (url: string, file: File): Promise<boolean> => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      await axios.put(url, file, {
        headers: {
          accept: 'multipart/form-data',
        },
      });
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  };
}

export default s3Adapter;
