import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import useScrollDirection from '../../../utility/useScrollDirection';
interface Props {
  openModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = makeStyles(() => ({
  logo: {
    marginTop: '10px',
    marginLeft: '10px',
  },
  open: {
    marginTop: '0px',
    transitionProperty: 'margin-top',
    transitionDuration: '1s',
    transitionDelay: '0s',
    transitionTimingFunction: 'ease',
  },
  hidden: {
    marginTop: '-100px',
    transitionProperty: 'margin-top',
    transitionDuration: '1s',
    transitionDelay: '0s',
    transitionTimingFunction: 'ease',
  },
  header_base: {
    position: 'fixed',
    height: '60px',
    width: '100%',
    display: 'flex',
    zIndex: '1003',
  },
  search_button_base: { marginLeft: 'auto', marginRight: '0' },
  division_base: {
    position: 'fixed',
    width: '100%',
    height: '0px',
    zIndex: '1003',
  },
  division: {
    marginRight: 'auto',
    marginLeft: 'auto',
    height: '0px',
    width: '90%',
    marginTop: '50px',
    borderBottom: '1px solid #2D2D2D',
  },
}));
const useStylesPC = makeStyles(() => ({
  logo: {
    marginTop: '10px',
    marginLeft: '20px',
  },
  open: {
    marginTop: '0px',
  },
  hidden: {
    marginTop: '0px',
  },
  header_base: {
    position: 'fixed',
    height: '60px',
    width: '100%',
    display: 'flex',
    zIndex: '1003',
  },
  search_button_base: { marginLeft: 'auto', marginRight: '10px' },
  division_base: {
    position: 'fixed',
    width: '100%',
    height: '0px',
    zIndex: '1003',
  },
  division: {
    marginRight: 'auto',
    marginLeft: 'auto',
    height: '0px',
    width: '99%',
    marginTop: '50px',
    borderBottom: '1px solid #2D2D2D',
  },
}));

const Header = (props: Props): JSX.Element => {
  const isDown = useScrollDirection();

  const isOpenHeader = (): boolean => {
    if (isDown !== null) {
      return !isDown;
    } else {
      return true;
    }
  };

  const classes = useStyles();
  const classesPC = useStylesPC();
  const classesForResponsive = (_isSideways: boolean) => {
    if (_isSideways) {
      return classes;
    } else {
      return classesPC;
    }
  };

  const [isSideways, setIsSideWays] = useState<boolean>(window.orientation === 0);
  window.addEventListener('orientationchange', () => {
    const orientation = window.orientation;
    if (orientation !== 0) {
      setIsSideWays(false);
    } else {
      setIsSideWays(true);
    }
  });

  return (
    <div
      className={
        isOpenHeader()
          ? classesForResponsive(isSideways).open
          : classesForResponsive(isSideways).hidden
      }
    >
      <div className={classesForResponsive(isSideways).header_base}>
        <div className={classesForResponsive(isSideways).logo}>
          <img src="svg/WOODONE_logo4.svg" width={119} height={30} />
        </div>
        <div className={classesForResponsive(isSideways).search_button_base}>
          <Button
            variant="outlined"
            onClick={() => {
              props.openModal(true);
            }}
            size="small"
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: '10px',
              border: 'none',
              background: 'transparent',
            }}
          >
            <img src="svg/modelId.svg" width={103} height={32}></img>
          </Button>
        </div>
      </div>
      <div className={classesForResponsive(isSideways).division_base}>
        <div className={classesForResponsive(isSideways).division}></div>
      </div>
    </div>
  );
};

export default Header;
