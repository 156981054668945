import axios, { AxiosResponse } from 'axios';
import { APP_END_POINT } from '../environment/environment';
import BaseModelData from '../types/copied/dto/data/BaseModelData';
/**
 * ベースモデルを管理するためのservice層
 */
/* eslint-disable */
export class BaseModelService {
  /* eslint-disable */
  public static getBaseModels = async (
    filteringImageId: string
  ): Promise<BaseModelData[] | undefined> => {
    const requestURL = APP_END_POINT + `/base_model/${filteringImageId}`;
    let res;
    await axios
      .get(requestURL, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
      .then((response: AxiosResponse<any>) => {
        //console.log('response: ', response.data.body);
        res = response.data.body;
        return res;
      })
      // catchでエラー時の挙動を定義
      .catch((err) => {
        //console.log('err:', err);
      });
    return res;
  };
}
