import axios, { AxiosResponse } from 'axios';
import PartModelData from '../types/copied/dto/data/PartModelData';
import { APP_END_POINT } from '../environment/environment';
/**
 * パーツモデルを管理するためのservice層
 */
/* eslint-disable */
export class PartsModelService {
  /* eslint-disable */
  public static getPartModels = async (
    baseModelId: string,
    filteringImageId: string
  ): Promise<PartModelData[] | undefined> => {
    const requestURL = APP_END_POINT + `/part_model/${baseModelId}/${filteringImageId}`;
    let res;
    await axios
      .get(requestURL, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
      .then((response: AxiosResponse<any>) => {
        //console.log('response: ', response.data.body);
        res = response.data.body;
        return res;
      })
      // catchでエラー時の挙動を定義
      .catch((err) => {
        //console.log('err:', err);
      });
    return res;
  };
}
