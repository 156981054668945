import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { CircularProgress, Tooltip } from '@mui/material';

interface Props {
  isUploadDone: boolean;
}

const useStyles = makeStyles(() => ({
  ar_result_base: {
    display: 'flex',
    width: '100%',
    marginTop: '3px',
    marginBottom: '10px',
  },
  ar_result_button_base: {
    margin: '0px',
    marginLeft: '14px',
    marginTop: 'auto',
  },
  ar_result_model_id_base: {
    display: 'flex',
    marginLeft: 'auto',
    marginRight: '20px',
  },
  ar_result_model_id_title: {
    fontSize: '0.8rem',
    marginTop: 'auto',
    marginBottom: 'auto',
    color: '#939393',
    fontWeight: '400',
  },
  ar_result_model_id: {
    marginLeft: '10px',
    marginTop: 'auto',
    marginBottom: 'auto',
    fontSize: '1rem',
    lineHeight: ' 130%',
    fontWeight: '700',
    color: '#343434',
  },
  transparent_button: {
    border: 'none',
    background: 'transparent',
    marginBottom: 'auto',
    marginTop: 'auto',
    cursor: 'pointer',
  },
  ar_result_model_id_copy_button: { marginTop: 'auto' },
  ar_button_text: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '124px',
    height: '28px',
    background: '#FFFFFF',
    border: ' 1px solid #939393',
    borderRadius: '24px',
    color: '#939393',
    fontWeight: '700',
    fontSize: '12px',
  },
  ar_button_icon: {
    marginLeft: '5px',
  },
}));

const ARResultSection = (props: Props): JSX.Element => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const modelId =
    sessionStorage.getItem('compoundModelId') === null
      ? ''
      : sessionStorage.getItem('compoundModelId');
  const [resultText, setResultText] = useState<string>(modelId!);
  const [canPressCopyButton, setCanPressCopyButton] = useState<boolean>(true);

  const copyToClipboard = async (): Promise<void> => {
    if (!canPressCopyButton) {
      return;
    } else {
      setCanPressCopyButton(false);
    }
    handleTooltipOpen();
    setTimeout(() => {
      handleTooltipClose();
      setCanPressCopyButton(true);
    }, 1000);
    await global.navigator.clipboard.writeText(resultText);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const onClickShowAR = () => {
    const arModelUrl = sessionStorage.getItem('arModelUrl');
    if (arModelUrl !== null) {
      window.location.href = arModelUrl;
    }
  };

  return (
    <div className={classes.ar_result_base}>
      <div className={classes.ar_result_button_base}>
        {props.isUploadDone ? (
          <button
            onClick={() => {
              onClickShowAR();
            }}
            className={classes.transparent_button}
          >
            <div className={classes.ar_button_text}>
              <div>ARで表示する</div>
              <img
                src="svg/UnionGray.svg"
                className={classes.ar_button_icon}
                width={16}
                height={16}
              />
            </div>
          </button>
        ) : (
          <button onClick={() => {}} className={classes.transparent_button}>
            <div>
              <div className={classes.ar_button_text}>
                <div>AR表示準備中</div>
                <CircularProgress style={{ color: '#939393', marginLeft: '5px' }} size={16} />
              </div>
            </div>
          </button>
        )}
      </div>
      <div className={classes.ar_result_model_id_base}>
        <div>
          <div className={classes.ar_result_model_id_title}>{'モデルID'}</div>
          <div className={classes.ar_result_model_id}>{resultText}</div>
        </div>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title="コピー"
          arrow
        >
          <div className={classes.ar_result_model_id_copy_button}>
            <button
              onClick={() => {
                copyToClipboard()
                  .catch(() => {})
                  .then(() => {})
                  .catch(() => 'obligatory catch');
              }}
              className={classes.transparent_button}
            >
              <img src="svg/copy_icon.svg" />
            </button>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default ARResultSection;
