import { useEffect, useState } from 'react';

const useScrollDirection = (): boolean | null => {
  const [scrollDirection, setScrollDirection] = useState<boolean | null>(null);
  let lastScrollY = window.pageYOffset;

  const updateScrollDirection = (): void => {
    const scrollY = window.pageYOffset;
    const direction = scrollY > lastScrollY;
    const documentHeight = document.documentElement.scrollHeight;
    const viewHeight = document.documentElement.clientHeight;

    //スクロールできないならヘッダー復活
    if (documentHeight - viewHeight < 0.01) {
      setScrollDirection(direction);
    } else {
      if (scrollY / (documentHeight - viewHeight) < 0.99) {
        if (
          direction !== scrollDirection &&
          (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)
        ) {
          // 下がるときはそのまま
          if (direction) {
            setScrollDirection(direction);
          } else {
            // 上がり切るまで変更しない
            if (scrollY / (documentHeight - viewHeight) < 0.05) {
              setScrollDirection(direction);
            }
          }
        }
      }
    }

    lastScrollY = scrollY > 0 ? scrollY : 0;
  };

  useEffect(() => {
    window.addEventListener('scroll', updateScrollDirection); // add event listener
    return () => {
      window.removeEventListener('scroll', updateScrollDirection); // clean up
    };
  }, [scrollDirection]);

  return scrollDirection;
};

export default useScrollDirection;
