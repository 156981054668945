import { useState } from 'react';
import ComplexPriceData from '../../types/copied/dto/data/ComplexPriceData';
import { ComplexPriceUsecase } from '../../usecases/ComplexPriceUsecase';

/**
 * 複合価格を管理するためのhooks
 */

const useComplexPrice = (baseModelId: string): [ComplexPriceData[], () => Promise<void>] => {
  const [partModels, setPartModel] = useState<ComplexPriceData[]>([]);
  /**
   * 該当ベースモデル取得
   */

  const getComplexPriceSet = async (): Promise<void> => {
    const res = await ComplexPriceUsecase.getComplexPriceSet(baseModelId);
    if (res.length !== 0) setPartModel(res);
  };
  return [partModels, getComplexPriceSet];
};

export default useComplexPrice;
