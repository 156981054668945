import React, { FC } from 'react';
import PartModelData from '../../types/copied/dto/data/PartModelData';
import { makeStyles } from '@mui/styles';
import CommonModal from '../components/common/CommonModal';
import { APP_ASSET_END_POINT } from '../../environment/environment';

interface Props {
  /** 説明するパーツモデル */
  partsModel: PartModelData;
  // モーダルの状態
  open: boolean;
  // モーダルを閉じる
  handleClose: () => void;
}
const useStyles = makeStyles(() => ({
  thumbnail_img: {
    height: '100%',
    width: '100%',
  },
  part_model_name: {
    marginTop: '5px',
    fontSize: '0.8rem',
    color: '#626262',
  },
  part_model_explanation: {
    marginTop: '10px',
    overflow: 'auto',
    height: '30vh',
    width: '100%',
    display: 'block',
    fontSize: '0.9rem',
    color: '#343434',
  },
}));

/**
 * パーツモデル詳細説明
 */
const PartModelExplainModal: FC<Props> = (props: Props) => {
  const handleClose = props.handleClose;
  const classes = useStyles();

  const contents = (
    <div>
      <img
        src={APP_ASSET_END_POINT + props.partsModel.thumbnail_url}
        className={classes.thumbnail_img}
      />
      <div className={classes.part_model_name}>{props.partsModel.name}</div>
      <div className={classes.part_model_explanation}>{props.partsModel.explain}</div>
    </div>
  );

  return <CommonModal open={props.open} handleClose={handleClose} contents={contents} />;
};

export default PartModelExplainModal;
