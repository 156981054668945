import React, { useState } from 'react';
import CommonPageTitle from '../common/CommonPageTitle';

const SelectPageTitle = (): JSX.Element => {
  const [isSideways, setIsSideWays] = useState<boolean>(window.orientation === 0);
  window.addEventListener('orientationchange', () => {
    const orientation = window.orientation;
    if (orientation !== 0) {
      setIsSideWays(false);
    } else {
      setIsSideWays(true);
    }
  });
  return (
    <div>
      {!isSideways ? (
        <CommonPageTitle
          titleIconPath="svg/FrameStep03.svg"
          titleText="色やデザインをお選びください。次画面でARによる確認ができます。"
        />
      ) : (
        <CommonPageTitle
          titleIconPath="svg/FrameStep03.svg"
          titleText="色やデザインをお選びください"
          titleTextSecond="次画面でARによる確認ができます"
        />
      )}
    </div>
  );
};

export default SelectPageTitle;
