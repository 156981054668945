import React, { useState } from 'react';
import CommonPageTitle from '../common/CommonPageTitle';

const ResultPageTitle = (): JSX.Element => {
  const [isSideways, setIsSideWays] = useState<boolean>(window.orientation === 0);
  window.addEventListener('orientationchange', () => {
    const orientation = window.orientation;
    if (orientation !== 0) {
      setIsSideWays(false);
    } else {
      setIsSideWays(true);
    }
  });
  return (
    <div>
      {!isSideways ? (
        <CommonPageTitle
          titleIconPath="svg/FrameStep4.svg"
          titleText="お選びいただいた仕様で、ＡＲ表示が出来ます"
        />
      ) : (
        <CommonPageTitle
          titleIconPath="svg/FrameStep4.svg"
          titleText="お選びいただいた仕様で"
          titleTextSecond="ＡＲ表示が出来ます"
        />
      )}
    </div>
  );
};

export default ResultPageTitle;
