import React from 'react';
import { useHistory } from 'react-router-dom';
import CommonFooter from '../common/CommonFooter';

interface Props {
  isSelectedFilteringImage: boolean;
}

const FilteringImageFooter = (props: Props): JSX.Element => {
  const history = useHistory();

  const onClickConfirmButton = (): void => {
    history.push('/base_model');
  };

  return (
    <CommonFooter
      canMoveToNextPage={props.isSelectedFilteringImage}
      onClickBack={() => {}}
      onClickNext={onClickConfirmButton}
      nextButtonText="次へ"
    />
  );
};

export default FilteringImageFooter;
