import React, { FC } from 'react';
import { makeStyles } from '@mui/styles';
import PartModelData from '../../../types/copied/dto/data/PartModelData';
import { Collapse, List, ListItemButton, ListSubheader } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import CardSection from './CardSection';

interface Props {
  selectedModel: PartModelData;
  selectList: PartModelData[];
  setValue: (value: React.SetStateAction<PartModelData>) => void;
  partModelTitleName: string;
  subPartModelTypeName: string;
  onClickSelect: (
    partModel: PartModelData,
    _setValue: (value: React.SetStateAction<PartModelData>) => void
  ) => void;
  onClickExplanation: (partModel: PartModelData) => void;
}

const useStyles = makeStyles(() => ({
  row_base: {
    margin: '10px',
    marginTop: '0px',
    marginBottom: '0px',
    position: 'relative',
    paddingBottom: '0px',
  },
  row_title: {
    fontSize: '18px',
    fontWeight: 700,
    fontStyle: 'normal',
    lineHeight: '130%',
    color: '#343434',
    display: 'flex',
  },
  sub_name_base: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: '10px',
    height: '13px',
    background: '#D7D7D7',
    padding: '2px 4px',
    alignItems: 'flex-start',
    gap: '8px',
    display: 'flex',
    flexDirection: 'row',
  },
  sub_name: {
    fontWeight: 300,
    fontSize: '10px',
    lineHeight: '130%',
    fontStyle: 'normal',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

const PartModelSelectSection: FC<Props> = (props: Props): JSX.Element => {
  // 扉デザインのトグル
  const [openCardRow, setOpenCardRow] = React.useState(true);
  const handleOpenCardRowClick = (): void => {
    setOpenCardRow(!openCardRow);
  };
  const classes = useStyles();

  const PartModelSection = (): JSX.Element => {
    return (
      <div className={classes.row_base}>
        <List
          sx={{ width: '100%', bgcolor: 'background.paper', paddingBottom: '0px' }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={<ListSubheader component="div" id="nested-list-subheader"></ListSubheader>}
        >
          <ListItemButton
            onClick={handleOpenCardRowClick}
            style={{
              paddingLeft: '0px',
              paddingBottom: '0px',
            }}
          >
            <div className={classes.row_title}>
              {props.partModelTitleName}
              <div className={classes.sub_name_base}>
                <div className={classes.sub_name}>{props.subPartModelTypeName}</div>
              </div>
            </div>
            {openCardRow ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openCardRow} timeout="auto" unmountOnExit>
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  overflowX: 'scroll',
                  marginTop: '5px',
                  whiteSpace: 'nowrap',
                }}
              >
                <CardSection
                  selectList={props.selectList}
                  selectedPartModel={props.selectedModel}
                  setValue={props.setValue}
                  onClickSelect={props.onClickSelect}
                  onClickExplanation={props.onClickExplanation}
                />
              </div>
            </div>
          </Collapse>
        </List>
      </div>
    );
  };
  return <div>{PartModelSection()}</div>;
};

export default PartModelSelectSection;
