import axios, { AxiosResponse } from 'axios';
import {
  APP_CP_ID,
  APP_END_POINT,
  APP_RITTAI_PATH,
  APP_WOODONE_SIM_PATH,
} from '../environment/environment';
import CompoundModelData from '../types/copied/dto/data/CompoundModelData';
import RegisterCompoundModelReq from '../types/original/ RegisterCompoundModelReq';
/**
 * パーツモデルを管理するためのservice層
 */
/* eslint-disable */
export class CompoundModelService {
  /* eslint-disable */
  public static getCompoundModel = async (
    compoundModelId: string
  ): Promise<CompoundModelData | undefined> => {
    const requestURL = APP_END_POINT + `/compound_model/${compoundModelId}`;
    let res;
    await axios
      .get(requestURL, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
      .then((response: AxiosResponse<any>) => {
        //console.log('response: ', response.data.body);
        res = response.data.body;
        return res;
      })
      // catchでエラー時の挙動を定義
      .catch((err) => {
        //console.log('err:', err);
      });

    return res;
  };

  public static registerCompoundModel = async (req: RegisterCompoundModelReq) => {
    const requestURL = APP_END_POINT + '/compound_model';
    let res;
    const data = `{"id": "${req.compoundModelId}", "model_url": "${req.modelURL}", "base_model_id": "${req.baseModelId}", "part_models": ["${req.washerId}", "${req.heatingId}", "${req.rangeId}", "${req.wetAreaId}", "${req.doorAreaId}"]}`;
    await axios
      .post(requestURL, data, { headers: { 'Content-Type': 'application/json' } })
      .then((response: AxiosResponse<any>) => {
        //console.log('response: ', response.data.body);
        res = response.data.body;
        return res;
      })
      // catchでエラー時の挙動を定義
      .catch((err) => {
        //console.log('err:', err);
      });
    return res;
  };

  public static createUploadCompoundModelURL = async () => {
    const requestURL = `${APP_RITTAI_PATH}/api/organizations/${APP_CP_ID}/items`;
    let res;
    let res2;
    const data = `{"code": "api-woodone", "name": "", "placement": "FLOOR", "itemVariations": [{"glbFileName": "sample.glb", "isPublic": true, "code": "", "name": "", "color":"","siteUrl": "${APP_WOODONE_SIM_PATH}"}]}`;

    await axios
      .post(requestURL, data, { headers: { 'Content-Type': 'application/json' } })
      .then((response: AxiosResponse<any>) => {
        //console.log('response: ', response.data.models[0].glbUrl);
        res = response.data.models[0].glbUrl;
        res2 = response.data.models[0].itemVariationId;
        return res;
      })
      // catchでエラー時の挙動を定義
      .catch((err) => {
        //console.log('err:', err);
      });
    return {
      url: res,
      id: res2,
    };
  };
}
