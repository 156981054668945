import ItemSelectionData from '../../types/copied/dto/data/ItemSelectionData';
import { useState } from 'react';
import { ItemSelectionUsecase } from '../../usecases/ItemSelectionUsecase';

/**
 * 複合アイテムを管理するためのhooks
 */

const useItemSelection = (): [ItemSelectionData[], () => Promise<void>] => {
  const [itemSelections, setItemSelections] = useState<ItemSelectionData[]>([]);
  /**
   * 全複合アイテムの取得
   */
  const getItemSelections = async (): Promise<void> => {
    const res = await ItemSelectionUsecase.getItemSelections();
    if (res.length !== 0) setItemSelections(res);
  };
  return [itemSelections, getItemSelections];
};

export default useItemSelection;
