import { BaseModelService } from '../services/BaseModelService';
import BaseModelData from '../types/copied/dto/data/BaseModelData';

/**
 * パーツモデルに関するユースケース層
 */
export class BaseModelUsecase {
  /**
   * 該当ベースモデル取得
   */
  public static getBaseModels = async (baseModelId: string): Promise<BaseModelData[]> => {
    const res = await BaseModelService.getBaseModels(baseModelId);
    if (res === undefined) return [];
    return res;
  };
}
