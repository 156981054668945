import React, { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { APP_ASSET_END_POINT } from '../../../environment/environment';

interface Props {
  titleName: string;
  details: string;
  thumbnail: string;
}

const useStyles = makeStyles(() => ({
  row_base: { display: 'flex', height: '58px', marginLeft: '4px' },

  row_title: {
    marginTop: 'auto',
    marginBottom: 'auto',
    color: '#A99679',
    fontWeight: '700',
    fontSize: '12px',
    lineHeight: '130%',
    width: '120px',
  },
  model_name: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: '8px',
    color: '#343434',
    fontSize: '12px',
    width: '100%',
  },
  model_img: { width: '56px', height: '42px', marginTop: 'auto', marginBottom: 'auto' },
  divider: {
    marginRight: 'auto',
    marginLeft: 'auto',
    height: '0px',
    width: '100%',
    borderBottom: '1px solid #D9D9D9',
  },
}));

const CommonBaseCheckSection: FC<Props> = (props: Props): JSX.Element => {
  const classes = useStyles();

  const BaseCheckRow = (titleName: string, details: string, thumbnail: string): JSX.Element => {
    return (
      <div>
        <div className={classes.row_base}>
          <div className={classes.row_title}>{titleName}</div>

          <img src={APP_ASSET_END_POINT + thumbnail} className={classes.model_img} />

          <div className={classes.model_name}>{details}</div>
        </div>
        <div className={classes.divider}></div>
      </div>
    );
  };

  return BaseCheckRow(props.titleName, props.details, props.thumbnail);
};

export default CommonBaseCheckSection;
