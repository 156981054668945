import React, { FC } from 'react';
import { makeStyles } from '@mui/styles';
import PartModelData from '../../../types/copied/dto/data/PartModelData';
import { Card, CardActionArea, CardMedia } from '@mui/material';
import { APP_ASSET_END_POINT } from '../../../environment/environment';

interface Props {
  selectList: PartModelData[];
  selectedPartModel: PartModelData;
  setValue: (value: React.SetStateAction<PartModelData>) => void;
  onClickSelect: (
    partModel: PartModelData,
    _setValue: (value: React.SetStateAction<PartModelData>) => void
  ) => void;
  onClickExplanation: (partModel: PartModelData) => void;
}

const useStyles = makeStyles(() => ({
  selected_base: {
    position: 'absolute',
    top: '0px',
    right: '4px',
    background: 'rgba(63, 63, 63, 0.5)',
    width: '136px', //36vw => 1vw 4px
    height: '102px',
  },
  selected_icon: {
    position: 'absolute',
    top: '32px',
    left: '54px',
  },
  selected_icon_img: { width: '30px', height: '45px' },
  card_action_area: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '136px',
    height: '102px',
  },
  explanation_button: {
    position: 'absolute',
    top: '72px',
    right: '4px',
    border: 'none',
    background: 'transparent',
  },
  explanation_icon_img: {
    height: '24px',
    width: '24px',
  },
  card_name: {
    marginTop: '10px',
    maxHeight: '340px',
    fontWeight: 700,
    fontStyle: 'normal',
    lineHeight: '130%',
    fontSize: '10px',
    width: '140px',
    whiteSpace: 'pre-wrap',
    verticalAlign: 'top',
  },
}));

const CardSection: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const CardElementsRow = (): JSX.Element[] => {
    return props.selectList.map((t, index) => {
      let selectImage;
      if (props.selectedPartModel !== undefined) {
        if (t.id === props.selectedPartModel.id) {
          selectImage = (
            <div>
              <div className={classes.selected_base}>
                <div className={classes.selected_icon}>
                  <img src="svg/select.svg" className={classes.selected_icon_img} />
                </div>
              </div>
            </div>
          );
        } else {
          selectImage = <div></div>;
        }
      }
      return (
        // TODO:以下のスタイルをクラスにすると適応が外れる
        <Card
          style={{
            borderRadius: '0px',
            display: 'inline-block',
            marginRight: '10px',
            marginTop: '0px',
            position: 'relative',
            boxShadow: '0 0 0 0',
            marginBottom: '10px',
            verticalAlign: 'top',
          }}
          key={index + 200}
        >
          <CardActionArea
            onClick={() => {
              props.onClickSelect(t, props.setValue);
            }}
            className={classes.card_action_area}
          >
            <CardMedia
              component="img"
              loading="lazy"
              image={APP_ASSET_END_POINT + t.thumbnail_url}
              alt=""
              style={{
                filter: 'drop-shadow(4px 3px 1px rgba(63, 63, 63, 0.2))',
                width: '136px',
                height: '102px',
                transform: 'translateZ(0)',
              }}
            />
          </CardActionArea>
          {selectImage}
          <button
            className={classes.explanation_button}
            onClick={() => {
              props.onClickExplanation(t);
            }}
          >
            <img src="svg/md.svg" className={classes.explanation_icon_img}></img>
          </button>
          <div className={classes.card_name}>{` ${t.name}`}</div>
        </Card>
      );
    });
  };
  return <div>{CardElementsRow()}</div>;
};

export default CardSection;
