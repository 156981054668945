import * as THREE from 'three';

class SceneWrapper extends THREE.Scene {
  /**
   * 配列内のオブジェクト全てをシーンに追加する
   * @param {THREE.Object3D} objects オブジェクト配列
   */
  addAll(objects: THREE.Object3D[]): void {
    objects.forEach((obj) => this.add(obj));
  }
}

export default SceneWrapper;
