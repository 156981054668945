import { useState } from 'react';
import PartModelData from '../../types/copied/dto/data/PartModelData';
import { PartsModelsUsecase } from '../../usecases/PartsModelsUsecase';

/**
 * パーツモデルを管理するためのhooks
 */

const usePartModels = (
  baseModelId: string,
  filteringImageId: string
): [PartModelData[], () => Promise<void>] => {
  const [partModels, setPartModel] = useState<PartModelData[]>([]);
  /**
   * 該当パーツモデル取得
   */
  const getPartModels = async (): Promise<void> => {
    const res = await PartsModelsUsecase.getPartModels(baseModelId, filteringImageId);
    if (res.length !== 0) setPartModel(res);
  };
  return [partModels, getPartModels];
};

export default usePartModels;
