import React, { FC, useState } from 'react';
import CommonModal from '../components/common/CommonModal';
import { makeStyles } from '@mui/styles';
import FooterNextIconImage from '../components/common/FooterNextIconImage';
import { Tooltip } from '@mui/material';

interface Props {
  // モーダルの状態
  open: boolean;
  // モーダルを閉じる
  handleClose: () => void;
}

const useStyles = makeStyles(() => ({
  part_model_name: {
    marginTop: '16px',
    fontSize: '0.8rem',
    color: '#626262',
    whiteSpace: 'pre-wrap',
  },
  part_model_explanation: {
    marginTop: '10px',
    overflow: 'auto',
    height: '40px',
    width: '100%',
    display: 'block',
    fontSize: '0.9rem',
    color: '#343434',
  },
  transparent_button: {
    border: 'none',
    background: 'transparent',
    position: 'absolute',
    right: '16px',
  },
  ar_result_model_id_copy_button: {
    //marginTop: 'auto'
  },
  ar_result_model_id_title: {
    fontSize: '0.8rem',
    marginTop: 'auto',
    marginBottom: 'auto',
    color: '#939393',
    fontWeight: '400',
  },
  ar_result_model_id_base: {
    display: 'flex',
    marginLeft: 'auto',
    marginRight: '20px',
  },
  ar_result_model_id: {
    marginLeft: '10px',
    marginTop: 'auto',
    marginBottom: 'auto',
    fontSize: '1rem',
    lineHeight: ' 130%',
    fontWeight: '700',
    color: '#343434',
  },
}));

/**
 * 縦横回転に対応していない旨を見せる表示
 */
const ConfirmModal: FC<Props> = (props: Props) => {
  const openModal = props.open;
  const handleClose = props.handleClose;
  const classes = useStyles();

  const onClickConfirmButton = (): void => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: 'push_page_woodone' });
    window.open('https://www.woodone.co.jp/showroom/', '_blank');
  };

  const modelId =
    sessionStorage.getItem('compoundModelId') === null
      ? ''
      : sessionStorage.getItem('compoundModelId');

  const [canPressCopyButton, setCanPressCopyButton] = useState<boolean>(true);
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const copyToClipboard = async (): Promise<void> => {
    if (!canPressCopyButton) {
      return;
    } else {
      setCanPressCopyButton(false);
    }
    handleTooltipOpen();
    setTimeout(() => {
      handleTooltipClose();
      setCanPressCopyButton(true);
    }, 1000);
    await global.navigator.clipboard.writeText(modelId!);
  };

  const contents = (
    <div>
      <div className={classes.part_model_explanation}>{'モデルIDをお控え下さい'}</div>
      <div className={classes.ar_result_model_id_base}>
        <div>
          <div className={classes.ar_result_model_id_title}>{'モデルID'}</div>
          <div className={classes.ar_result_model_id}>{modelId!}</div>
        </div>
        <Tooltip
          style={{ display: 'flex', marginLeft: 'auto', marginRight: '0px', marginBottom: '20px' }}
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title="コピー"
          arrow
        >
          <div /*className={classes.ar_result_model_id_copy_button}*/>
            <button
              onClick={() => {
                copyToClipboard()
                  .catch(() => {})
                  .then(() => {})
                  .catch(() => 'obligatory catch');
              }}
              className={classes.transparent_button}
            >
              <img src="svg/copy_icon.svg" />
            </button>
          </div>
        </Tooltip>
      </div>
      <div className={classes.part_model_name}>
        {
          'このままショールームのご予約をされる場合は、モデルIDをお控え頂き＜予約へ進む＞ボタンからお進みください。\n 予約フォームの「ご検討情報：その他ご要望」欄にモデルIDをご記入（貼付）いただくと、こちらの仕様を基にご提案させていただきます。'
        }
      </div>
      <div style={{ width: '100%', height: '40px' }}>
        <button onClick={onClickConfirmButton} className={classes.transparent_button}>
          <FooterNextIconImage isEnable={true} text={'予約へ進む'} isUnionIcon={true} />
        </button>
      </div>
    </div>
  );
  return <CommonModal open={openModal} handleClose={handleClose} contents={contents} />;
};

export default ConfirmModal;
