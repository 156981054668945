import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import RendererWrapper from '../wrapper/RendererWrapper';
import SceneWrapper from '../wrapper/SceneWrapper';

/**
 * シーン内のオブジェクトを管理するシングルトン
 */
class ObjectManager {
  // シーン
  scene: SceneWrapper | undefined;

  // レンダラー
  renderer: RendererWrapper | undefined;

  // カメラ
  camera: THREE.PerspectiveCamera | undefined;

  // コントローラー
  controller: OrbitControls | undefined;

  // light1
  light1: THREE.DirectionalLight | undefined;
  // light2
  light2: THREE.DirectionalLight | undefined;
  // light3
  light3: THREE.DirectionalLight | undefined;
  // light4
  light4: THREE.DirectionalLight | undefined;
  // light5
  light5: THREE.DirectionalLight | undefined;
}

export default new ObjectManager();
