import React, { FC } from 'react';
import CommonModal from '../components/common/CommonModal';
import { makeStyles } from '@mui/styles';

interface Props {
  // モーダルの状態
  open: boolean;
  // モーダルを閉じる
  handleClose: () => void;
}

const useStyles = makeStyles(() => ({
  part_model_name: {
    marginTop: '5px',
    fontSize: '0.8rem',
    color: '#626262',
  },
  part_model_explanation: {
    marginTop: '10px',
    overflow: 'auto',
    height: '40px',
    width: '100%',
    display: 'block',
    fontSize: '0.9rem',
    color: '#343434',
  },
}));

/**
 * 縦横回転に対応していない旨を見せる表示
 */
const HeightWidthModal: FC<Props> = (props: Props) => {
  const open = props.open;
  const handleClose = props.handleClose;
  const classes = useStyles();

  const contents = (
    <div>
      <div className={classes.part_model_explanation}>{'縦画面でご利用ください'}</div>
      <div className={classes.part_model_name}>
        {
          '現在ご使用中のデバイスでの横画面の表示時は対応しておらず、表示が崩れる可能性があります。画面の表示がおかしい場合はリロードをお試しください。'
        }
      </div>
    </div>
  );
  return <CommonModal open={open} handleClose={handleClose} contents={contents} />;
};

export default HeightWidthModal;
