import React, { FC, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Card, CardActionArea, CardMedia } from '@mui/material';
import { APP_ASSET_END_POINT } from '../../../environment/environment';
import FilteringImageData from '../../../types/copied/dto/data/FilteringImageData';

interface Props {
  selectedFilteringImage: FilteringImageData | undefined;
  selectList: FilteringImageData[];
  setValue: (value: React.SetStateAction<FilteringImageData | undefined>) => void;
}

const useStyles = makeStyles(() => ({
  whole_base: {
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  row_base: {
    margin: '10px',
    marginTop: '0px',
    marginBottom: '0px',
    position: 'relative',
    paddingBottom: '0px',
  },
  selected_base: {
    position: 'absolute',
    top: '0px',
    right: '0px',
    background: 'rgba(63, 63, 63, 0.5)',
    width: '92vw',
    height: '69vw',
  },
  selected_icon: {
    position: 'absolute',
    top: '27vw',
    right: '41vw',
  },
  selected_icon_img: { width: '48px', height: '73px' },
  card_name: {
    marginTop: '10px',
    marginBottom: '0px',
    marginLeft: '10px',
    fontWeight: 700,
    fontStyle: 'normal',
    lineHeight: '130%',
    fontSize: '16px',
  },
}));

const styleClass = {
  cardMedia: {
    filter: 'drop-shadow(4px 3px 1px rgba(63, 63, 63, 0.2))',
    width: '92vw',
    height: '69vw',
    transform: 'translateZ(0)',
  },
  cardActionArea: {
    position: 'relative',
    width: '92vw',
    height: '69vw',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginBottom: '40px',
  },
  cardBase: {
    borderRadius: '0px',
    marginTop: '0px',
    position: 'relative',
    boxShadow: '0 0 0 0',
  },
};

const useStylesPC = makeStyles(() => ({
  whole_base: {
    marginTop: '160px',
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  row_base: {
    margin: '10px',
    marginTop: '0px',
    marginBottom: '0px',
    position: 'relative',
    paddingBottom: '0px',
  },
  selected_base: {
    position: 'absolute',
    top: '0px',
    right: '0px',
    background: 'rgba(63, 63, 63, 0.5)',
    width: '100%',
    height: '100%',
  },
  selected_icon: {
    position: 'absolute',
    top: '192px',
    right: '252px',
  },
  selected_icon_img: { width: '60px', height: '60px' },
  card_name: {
    marginTop: '10px',
    marginBottom: '0px',
    marginLeft: '10px',
    fontWeight: 700,
    fontStyle: 'normal',
    lineHeight: '130%',
    fontSize: '12px',
    width: '600px',
  },
}));

const styleClassPC = {
  cardMedia: {
    filter: 'drop-shadow(4px 3px 1px rgba(63, 63, 63, 0.2))',
    width: '100%',
    height: '100%',
    transform: 'translateZ(0)',
  },
  cardActionArea: {
    position: 'relative',
    width: '560px',
    height: '420px',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginBottom: '40px',
  },
  cardBase: {
    borderRadius: '0px',
    marginTop: '0px',
    position: 'relative',
    boxShadow: '0 0 0 0',
  },
};

const FilteringImageSelectLine: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const classesPC = useStylesPC();
  const classesForResponsive = (_isSideways: boolean) => {
    if (_isSideways) {
      return classes;
    } else {
      return classesPC;
    }
  };

  const styleForResponsive = (_isSideways: boolean) => {
    if (_isSideways) {
      return styleClass;
    } else {
      return styleClassPC;
    }
  };

  const [isSideways, setIsSideWays] = useState<boolean>(window.orientation === 0);
  window.addEventListener('orientationchange', () => {
    const orientation = window.orientation;
    if (orientation !== 0) {
      setIsSideWays(false);
    } else {
      setIsSideWays(true);
    }
  });
  const filteringNameSplit = (name: string): JSX.Element[] => {
    let names = name.split('＃');
    let nameElements = names.map((e, index) => {
      return <div style={{ float: 'left' }}>{'＃' + e}</div>;
    });
    return nameElements;
  };

  const CardElementsRow = (): JSX.Element[] => {
    return props.selectList.map((e, index) => {
      let selectImage;
      if (props.selectedFilteringImage !== undefined) {
        if (e.id === props.selectedFilteringImage.id) {
          selectImage = (
            <div>
              <div className={classesForResponsive(isSideways).selected_base}>
                <div className={classesForResponsive(isSideways).selected_icon}>
                  <img
                    src="svg/select.svg"
                    className={classesForResponsive(isSideways).selected_icon_img}
                  />
                </div>
              </div>
            </div>
          );
        } else {
          selectImage = <div></div>;
        }
      }

      return (
        <div style={{ marginLeft: '10px', marginRight: '10px' }}>
          <div className={classesForResponsive(isSideways).card_name}>{/*` ${e.name}`*/}</div>
          <Card sx={styleForResponsive(isSideways).cardBase} key={index + 300}>
            <CardActionArea
              onClick={() => {
                props.setValue(e);
                //セッション情報に格納する。
                sessionStorage.setItem('filteringImageId', e.id);
              }}
              sx={styleForResponsive(isSideways).cardActionArea}
            >
              <CardMedia
                component="img"
                image={APP_ASSET_END_POINT + e.image_url}
                loading="lazy"
                alt=""
                sx={styleForResponsive(isSideways).cardMedia}
              />
              <div
                style={{
                  background: 'rgb(223 221 219)',
                  filter: 'drop-shadow(4px 3px 1px rgba(63, 63, 63, 0.2))',
                  height: '36px',
                  width: '100%',
                  fontSize: '12px',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {filteringNameSplit(e.name)}

                {/*' ＃かわいい ＃ファミリー ＃ナチュラル ＃わくわく \n ＃家族団らん ＃にぎやか'*/}
                {/*` ${e.name}`*/}
              </div>
              {selectImage}
            </CardActionArea>
          </Card>
        </div>
      );
    });
  };
  return <div className={classesForResponsive(isSideways).whole_base}>{CardElementsRow()}</div>;
};

export default FilteringImageSelectLine;
