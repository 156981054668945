import { PartsModelService } from '../services/PartsModelService';
import PartModelData from '../types/copied/dto/data/PartModelData';

/**
 * パーツモデルに関するユースケース層
 */
export class PartsModelsUsecase {
  /**
   * 該当パーツモデル取得
   */
  public static getPartModels = async (
    baseModelId: string,
    filteringImageId: string
  ): Promise<PartModelData[]> => {
    const res = await PartsModelService.getPartModels(baseModelId, filteringImageId);
    if (res === undefined) return [];
    return res;
  };
}
