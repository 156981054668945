import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import BaseModelData from '../../types/copied/dto/data/BaseModelData';
import BaseModelFooter from '../components/footer/BaseModelFooter';
import Header from '../components/header/Header';
import BaseModelSelectLine from '../components/contents/BaseModelSelectLine';
import BaseModelPageTitle from '../components/title/BaseModelPageTitle';
import useBaseModel from '../hooks/useBaseModel';
import ResearchModelModal from '../modal/ResearchModelModal';
import { UtilityTool } from '../../utility/UtilityTool';
import HeightWidthModal from '../modal/HeightWidthModal';
import { UtilityWithSession } from '../../utility/UtilityWithSession';

const useStyles = makeStyles(() => ({
  whole_base: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    display: 'flex',
  },
  root_upper: {
    position: 'fixed',
    background: 'white',
    zIndex: '1001',
    width: '100%',
    color: '#343434',
  },
  root_lower: {
    marginTop: '120px',
  },
}));

const BaseModelPage = (): JSX.Element => {
  const classes = useStyles();

  //通信
  const filteringImageId = sessionStorage.getItem('filteringImageId');
  const [baseModelList, getBaseModelDataSet] = useBaseModel(
    filteringImageId === null ? 'all' : filteringImageId!!
  );

  const [researchModalOpen, setResearchModalOpen] = useState<boolean>(false);
  const [selectedBaseModel, setSelectedBaseModel] = useState<BaseModelData>();

  const initialOpenAlertModal = (): boolean => {
    if (UtilityTool.isSmartPhone()) {
      return window.orientation !== 0;
    } else {
      return false;
    }
  };
  const [openAlertModal, setOpenAlertModal] = useState<boolean>(initialOpenAlertModal);

  window.addEventListener('orientationchange', () => {
    const orientation = window.orientation;
    if (orientation !== 0) {
      if (!UtilityTool.isTablet()) {
        setOpenAlertModal(true);
      } else {
        setOpenAlertModal(false);
      }
    } else {
      setOpenAlertModal(false);
    }
  });
  useEffect(() => {
    UtilityWithSession.clearSessionInfo();
    getBaseModelDataSet()
      .catch(() => {})
      .then(() => {})
      .catch(() => 'obligatory catch');
  }, []);
  return (
    <div className={classes.whole_base}>
      <Header openModal={setResearchModalOpen} />
      <div>
        <div className={classes.root_upper}>
          <BaseModelPageTitle />
        </div>
        <div>
          <div className={classes.root_lower}>
            <BaseModelSelectLine
              selectedBaseModel={selectedBaseModel}
              selectList={baseModelList}
              setValue={setSelectedBaseModel}
            />
          </div>
          <ResearchModelModal
            open={researchModalOpen}
            handleClose={() => {
              setResearchModalOpen(false);
            }}
          />
          <HeightWidthModal
            open={openAlertModal}
            handleClose={() => {
              setOpenAlertModal(false);
            }}
          />
          <BaseModelFooter isSelectedBaseModel={selectedBaseModel !== undefined} />
        </div>
      </div>
    </div>
  );
};

export default BaseModelPage;
