import s3Adapter from '../adapter/s3Adapter';
import { CompoundModelService } from '../services/CompoundModelService';
import ObjectManager from '../three/manager/ObjectManager';
import SceneWrapper from '../three/wrapper/SceneWrapper';
import CompoundModelData from '../types/copied/dto/data/CompoundModelData';
import { GLTFExporter } from '../utility/threejsModule/GLTFExporter';

/**
 * 合成モデルに関するユースケース層
 */
export class CompoundModelUsecase {
  /**
   * 合成モデル取得
   */
  public static getCompoundModel = async (
    compoundModelId: string
  ): Promise<CompoundModelData | undefined> => {
    const res = await CompoundModelService.getCompoundModel(compoundModelId);
    if (res === undefined) return undefined;
    return res;
  };

  /**
   * 合成モデルのアップロード
   */

  public static uploadCompoundModel = async (
    _url: string | undefined,
    uploadDoneCallback: () => void
  ) => {
    if (_url === undefined) {
      return;
    }

    if (
      ObjectManager.scene != undefined &&
      ObjectManager.light1 !== undefined &&
      ObjectManager.light2 !== undefined &&
      ObjectManager.light3 !== undefined &&
      ObjectManager.light4 !== undefined &&
      ObjectManager.light5 !== undefined
    ) {
      const sceneWithoutLight = CompoundModelUsecase.getSceneWithoutLight(
        ObjectManager.scene,
        ObjectManager.light1,
        ObjectManager.light2,
        ObjectManager.light3,
        ObjectManager.light4,
        ObjectManager.light5
      );
      if (sceneWithoutLight !== undefined) {
        CompoundModelUsecase.exportGLB(sceneWithoutLight, _url, uploadDoneCallback);
        ObjectManager.scene.add(ObjectManager.light1);
        ObjectManager.scene.add(ObjectManager.light2);
        ObjectManager.scene.add(ObjectManager.light3);
        ObjectManager.scene.add(ObjectManager.light4);
        ObjectManager.scene.add(ObjectManager.light5);
      }
    }
  };

  public static getSceneWithoutLight = (
    scene: SceneWrapper | undefined,
    light1: THREE.DirectionalLight | undefined,
    light2: THREE.DirectionalLight | undefined,
    light3: THREE.DirectionalLight | undefined,
    light4: THREE.DirectionalLight | undefined,
    light5: THREE.DirectionalLight | undefined
  ): SceneWrapper | undefined => {
    let sceneA: SceneWrapper | undefined;
    if (
      scene !== undefined &&
      light1 !== undefined &&
      light2 !== undefined &&
      light3 !== undefined &&
      light4 !== undefined &&
      light5 !== undefined
    ) {
      sceneA = scene.remove(light1);
      sceneA = scene.remove(light2);
      sceneA = scene.remove(light3);
      sceneA = scene.remove(light4);
      sceneA = scene.remove(light5);
    } else {
    }
    return sceneA;
  };

  public static exportGLB = (input: THREE.Scene, url: string, uploadDoneCallback: () => void) => {
    const gltfExporter = new GLTFExporter();
    const options = {
      trs: false,
      onlyVisible: false,
      binary: true,
      maxTextureSize: 4096,
    };
    /**
     * parseAsyncを使って非同期処理
     */

    gltfExporter.parse(
      input,
      async function (result: any) {
        if (result instanceof ArrayBuffer) {
          const blob = new Blob([result], { type: 'application/octet-stream' });
          const filename = 'sample.glb';
          const file = new File([blob], filename, { type: 'application/octet-stream' });
          const res = await s3Adapter.putObject(url, file).then(() => {
            uploadDoneCallback();
          });
        }
      },
      function (error: any) {
        // console.log('An error happened during parsing', error);
      },
      options
    );
  };
}
