import { useEffect, useState } from 'react';
import ItemSelectionData from '../../types/copied/dto/data/ItemSelectionData';
import PartModelData from '../../types/copied/dto/data/PartModelData';
import { UtilityTool } from '../../utility/UtilityTool';
import { UtilityWithSelection } from '../../utility/UtilityWithSelection';

/**
 * 選択されたパーツモデルを管理するためのhooks
 */

const useLayer3PartModels = (
  partModelDataSet: PartModelData[],
  itemSelections: ItemSelectionData[],
  typeName: string,
  selectedRootLayer1PartModel: PartModelData,
  selectedRootLayer2PartModel: PartModelData
): [
  PartModelData,
  (value: React.SetStateAction<PartModelData>) => void,
  PartModelData[],
  (value: React.SetStateAction<PartModelData[]>) => void
] => {
  const [selectedPartModel, setSelectedPartModel] = useState<PartModelData>(
    UtilityTool.getEmptyPartModelData()
  );
  const [list, setList] = useState<PartModelData[]>([]);
  const defaultList = UtilityWithSelection.extractLayer3List(
    typeName,
    selectedRootLayer1PartModel,
    selectedRootLayer2PartModel,
    partModelDataSet,
    itemSelections
  );

  const sessionId = () => {
    if (typeName === 'type_wet_area') {
      return 'tapId';
    }
    if (typeName === 'type_door_area') {
      return 'doorGrip';
    }
    return '';
  };

  // データベースからの情報が届いた時に実行する
  useEffect(() => {
    if (partModelDataSet.length === 0) {
      return;
    }
    if (itemSelections.length === 0) {
      return;
    }
    if (selectedRootLayer2PartModel.id === '') {
      return;
    }
    if (selectedRootLayer1PartModel.id === '') {
      return;
    }
    // 初期設定
    if (sessionStorage.getItem('tapId') === null) {
      setList(defaultList);
      setSelectedPartModel(defaultList[0]);
    } else {
      setList(defaultList);
      const layer3PartModel = UtilityTool.getByID(
        sessionStorage.getItem(sessionId()),
        UtilityWithSelection.getAllLayer3WithoutDependence(
          partModelDataSet,
          itemSelections,
          typeName
        )
      );
      setSelectedPartModel(layer3PartModel);
    }
  }, [
    partModelDataSet.length,
    itemSelections.length,
    selectedRootLayer2PartModel,
    selectedRootLayer1PartModel,
  ]);

  return [selectedPartModel, setSelectedPartModel, list, setList];
};

export default useLayer3PartModels;
