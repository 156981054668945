import React from 'react';
import PartModelData from '../../../types/copied/dto/data/PartModelData';
import PartModelSelectSection from './PartModelSelectSection';

interface Props {
  selectedDoorDesign: PartModelData;
  _doorDesignList: PartModelData[];
  setSelectedDoorDesign: (value: React.SetStateAction<PartModelData>) => void;

  selectedDoorColor: PartModelData;
  _doorColorList: PartModelData[];
  setSelectedDoorColor: (value: React.SetStateAction<PartModelData>) => void;

  selectedDoorGrip: PartModelData;
  _doorGripList: PartModelData[];
  setSelectedDoorGrip: (value: React.SetStateAction<PartModelData>) => void;

  selectedTabletop: PartModelData;
  _tabletopList: PartModelData[];
  setSelectedTabletop: (value: React.SetStateAction<PartModelData>) => void;

  selectedSink: PartModelData;
  _sinkList: PartModelData[];
  setSelectedSink: (value: React.SetStateAction<PartModelData>) => void;

  selectedTap: PartModelData;
  _tapList: PartModelData[];
  setSelectedTap: (value: React.SetStateAction<PartModelData>) => void;

  selectedWasher: PartModelData;
  _washerList: PartModelData[];
  setSelectedWasher: (value: React.SetStateAction<PartModelData>) => void;

  selectedRange: PartModelData;
  _rangeList: PartModelData[];
  setSelectedRange: (value: React.SetStateAction<PartModelData>) => void;

  selectedHeating: PartModelData;
  _heatingList: PartModelData[];
  setSelectedHeating: (value: React.SetStateAction<PartModelData>) => void;

  onClickSelect: (
    partModel: PartModelData,
    _setValue: (value: React.SetStateAction<PartModelData>) => void
  ) => void;
  onClickExplanation: (partModel: PartModelData) => void;
}

const PartModelsLine = (props: Props): JSX.Element => {
  return (
    <div>
      <PartModelSelectSection
        selectedModel={props.selectedDoorDesign}
        selectList={props._doorDesignList}
        setValue={props.setSelectedDoorDesign}
        partModelTitleName={'扉 / 樹種'}
        subPartModelTypeName={'door design'}
        onClickSelect={props.onClickSelect}
        onClickExplanation={props.onClickExplanation}
      />
      <PartModelSelectSection
        selectedModel={props.selectedDoorColor}
        selectList={props._doorColorList}
        setValue={props.setSelectedDoorColor}
        partModelTitleName={'カラー'}
        subPartModelTypeName={'door color'}
        onClickSelect={props.onClickSelect}
        onClickExplanation={props.onClickExplanation}
      />
      <PartModelSelectSection
        selectedModel={props.selectedDoorGrip}
        selectList={props._doorGripList}
        setValue={props.setSelectedDoorGrip}
        partModelTitleName={'取手'}
        subPartModelTypeName={'handle'}
        onClickSelect={props.onClickSelect}
        onClickExplanation={props.onClickExplanation}
      />
      <PartModelSelectSection
        selectedModel={props.selectedTabletop}
        selectList={props._tabletopList}
        setValue={props.setSelectedTabletop}
        partModelTitleName={'天板'}
        subPartModelTypeName={'tabletop'}
        onClickSelect={props.onClickSelect}
        onClickExplanation={props.onClickExplanation}
      />
      <PartModelSelectSection
        selectedModel={props.selectedSink}
        selectList={props._sinkList}
        setValue={props.setSelectedSink}
        partModelTitleName={'シンク'}
        subPartModelTypeName={'sink'}
        onClickSelect={props.onClickSelect}
        onClickExplanation={props.onClickExplanation}
      />
      <PartModelSelectSection
        selectedModel={props.selectedTap}
        selectList={props._tapList}
        setValue={props.setSelectedTap}
        partModelTitleName={'水栓'}
        subPartModelTypeName={'tap'}
        onClickSelect={props.onClickSelect}
        onClickExplanation={props.onClickExplanation}
      />
      <PartModelSelectSection
        selectedModel={props.selectedWasher}
        selectList={props._washerList}
        setValue={props.setSelectedWasher}
        partModelTitleName={'食洗機'}
        subPartModelTypeName={'dishwasher'}
        onClickSelect={props.onClickSelect}
        onClickExplanation={props.onClickExplanation}
      />
      <PartModelSelectSection
        selectedModel={props.selectedRange}
        selectList={props._rangeList}
        setValue={props.setSelectedRange}
        partModelTitleName={'レンジフード'}
        subPartModelTypeName={'range hood'}
        onClickSelect={props.onClickSelect}
        onClickExplanation={props.onClickExplanation}
      />
      <PartModelSelectSection
        selectedModel={props.selectedHeating}
        selectList={props._heatingList}
        setValue={props.setSelectedHeating}
        partModelTitleName={'加熱機器'}
        subPartModelTypeName={'heating devices'}
        onClickSelect={props.onClickSelect}
        onClickExplanation={props.onClickExplanation}
      />
    </div>
  );
};

export default PartModelsLine;
