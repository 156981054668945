import { FilteringImageService } from '../services/FilteringImageService';
import FilteringImageData from '../types/copied/dto/data/FilteringImageData';

/**
 * フィルタリング画像に関するユースケース層
 */
export class FilteringImageUsecase {
  /**
   * 該当ベースモデル取得
   */
  public static getFilteringImage = async (): Promise<FilteringImageData[]> => {
    const res = await FilteringImageService.getFilteringImage();
    if (res === undefined) return [];
    return res;
  };
}
