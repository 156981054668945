import React from 'react';
import { useHistory } from 'react-router-dom';
import { APP_CP_ID, APP_RITTAI_PATH } from '../../../environment/environment';
import { CompoundModelService } from '../../../services/CompoundModelService';
import PartModelData from '../../../types/copied/dto/data/PartModelData';
import RegisterCompoundModelReq from '../../../types/original/ RegisterCompoundModelReq';
import { CompoundModelUsecase } from '../../../usecases/CompoundModelUsecase';
import { UtilityTool } from '../../../utility/UtilityTool';
import { UtilityWithSelection } from '../../../utility/UtilityWithSelection';
import { UtilityWithSession } from '../../../utility/UtilityWithSession';
import CommonFooter from '../common/CommonFooter';

interface Props {
  price: string;
  setPageState: (value: React.SetStateAction<boolean>) => void;
  setIsUploadDone: (value: React.SetStateAction<boolean>) => void;
  partModelDataSet: PartModelData[];
}

const PartModelFooter = (props: Props): JSX.Element => {
  const _history = useHistory(); // eslint-disable-line
  const onClickBackButton = (): void => {
    UtilityWithSession.clearSessionInfo();
    _history.push('/base_model');
  };

  const onClickResultButton = async (): Promise<void> => {
    props.setPageState(true);
    history.pushState(null, '', '/simulate');
    const compoundModelId = UtilityTool.createRandomString();
    sessionStorage.setItem('compoundModelId', compoundModelId);
    sessionStorage.setItem('isResultPage', 'true');

    const doorAreaId: string = UtilityWithSelection.integrateAreaModel(
      sessionStorage.getItem('doorDesign') || '',
      sessionStorage.getItem('doorColor') || '',
      sessionStorage.getItem('doorGrip') || '',
      'type_door_area',
      props.partModelDataSet
    ).id;
    const wetAreaId: string = UtilityWithSelection.integrateAreaModel(
      sessionStorage.getItem('tabletopId') || '',
      sessionStorage.getItem('sinkId') || '',
      sessionStorage.getItem('tapId') || '',
      'type_wet_area',
      props.partModelDataSet
    ).id;

    const res = await CompoundModelService.createUploadCompoundModelURL();

    const modelUrl = `${APP_RITTAI_PATH}/index.html?id=${res.id}&cp=${APP_CP_ID}`;

    sessionStorage.setItem('arModelUrl', modelUrl);

    const request: RegisterCompoundModelReq = {
      compoundModelId: compoundModelId,
      modelURL: modelUrl,
      baseModelId: sessionStorage.getItem('baseModelId') || '',
      washerId: sessionStorage.getItem('washerId') || '',
      heatingId: sessionStorage.getItem('heatingId') || '',
      rangeId: sessionStorage.getItem('rangeId') || '',
      wetAreaId: wetAreaId,
      doorAreaId: doorAreaId,
    };
    CompoundModelService.registerCompoundModel(request);

    const uploadDone = () => {
      sessionStorage.setItem('isUploadDone', 'true');
      props.setIsUploadDone(true);
    };

    const res1 = await CompoundModelUsecase.uploadCompoundModel(res.url, uploadDone);
  };

  return (
    <CommonFooter
      canMoveToNextPage={true}
      onClickBack={onClickBackButton}
      onClickNext={onClickResultButton}
      ableBackButton={true}
      ablePriceText={true}
      price={props.price}
      nextButtonText="結果表示"
    />
  );
};

export default PartModelFooter;
