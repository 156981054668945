import React, { FC } from 'react';
import { makeStyles } from '@mui/styles';
import PartModelData from '../../../types/copied/dto/data/PartModelData';
import { UtilityTool } from '../../../utility/UtilityTool';

interface Props {
  itemTypeTitleName: string;
  _targetModel: PartModelData;
}

const useStyles = makeStyles(() => ({
  row_part_model_base: { display: 'flex', height: '40px', marginLeft: '4px' },

  row_title: {
    marginTop: 'auto',
    marginBottom: 'auto',
    color: '#A99679',
    fontWeight: '700',
    fontSize: '12px',
    lineHeight: '130%',
    width: '100px',
  },

  model_name: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: '8px',
    color: '#343434',
    fontSize: '12px',
    width: '100%',
  },
  divider: {
    marginRight: 'auto',
    marginLeft: 'auto',
    height: '0px',
    width: '100%',
    borderBottom: '1px solid #D9D9D9',
  },
}));

const CommonPartCheckSection: FC<Props> = (props: Props): JSX.Element => {
  const classes = useStyles();

  const PartCheckRow = (itemTypeTitleName: string, _targetModel: PartModelData): JSX.Element => {
    let targetModel;
    if (_targetModel === undefined) {
      targetModel = UtilityTool.getEmptyPartModelData();
    } else {
      targetModel = _targetModel;
    }
    return (
      <div>
        <div className={classes.row_part_model_base}>
          <div className={classes.row_title}>{itemTypeTitleName}</div>
          <div className={classes.model_name}>{targetModel.name}</div>
        </div>
        <div className={classes.divider}></div>
      </div>
    );
  };

  return PartCheckRow(props.itemTypeTitleName, props._targetModel);
};

export default CommonPartCheckSection;
