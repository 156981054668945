import axios, { AxiosResponse } from 'axios';
import { APP_END_POINT } from '../environment/environment';
import FilteringImageData from '../types/copied/dto/data/FilteringImageData';
/**
 * フィルタリング画像を管理するためのservice層
 */
/* eslint-disable */
export class FilteringImageService {
  /* eslint-disable */
  public static getFilteringImage = async (): Promise<FilteringImageData[] | undefined> => {
    const requestURL = APP_END_POINT + `/filtering_images`;
    let res;
    await axios
      .get(requestURL, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
      .then((response: AxiosResponse<any>) => {
        //console.log('response: ', response.data.body);
        res = response.data.body;
        return res;
      })
      // catchでエラー時の挙動を定義
      .catch((err) => {
        //console.log('err:', err);
      });
    return res;
  };
}
